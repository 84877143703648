import styles from './LoginPage.module.scss';
import logo from '../images/tem_smarthome_logo_light.png';
import Loader from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { UserCredential } from '../models/UserCredential';
import { Routings } from '../models/enums/Routings';
import { getSelectedPageAfterLogin, getUserCredential, saveUserCredential } from '../helpers/CookieHelper';
import { authenticate, checkCloudConnection, getCloudAccount, logout, setDemo } from '../helpers/HttpMethods';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isCloud } from '../env.json';
import { AccountInfo } from '@azure/msal-browser';
import CloudImg from '../images/cloud.svg';
import { Button, Form, Input } from 'antd';
import backgroundImg from './backgroundImg.jpg';
import { CloseOutlined } from '@ant-design/icons';

enum FormFields {
    username = 'username',
    password = 'password',
}

const LoginPage = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [cloudAccount, setCloudAccount] = useState<AccountInfo>();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        setCloudAccount(getCloudAccount());
        const userCredential = getUserCredential();

        if (isCloud && userCredential?.username) {
            checkCloud(userCredential);
            return;
        }

        if (userCredential && userCredential.refresh_token) {
            history.replace(Routings.MainPage);
        }
    }, []);

    const doLogin = async (e: { username: string; password: string }) => {
        const username = e.username;
        const password = e.password;

        isCloud ? checkCloud({ username, password }) : checkConnection({ username, password });
    };

    const checkCloud = async (userCredential: UserCredential) => {
        const result = await checkCloudConnection(userCredential);

        if (result) {
            saveUserCredential(userCredential);
            history.replace(Routings.MainPage);
            return;
        }

        toast.error(t('errors.connectionFailed'));
    };

    const checkConnection = async (userCredential: UserCredential) => {
        setIsLoading(true);

        if (await authenticate(userCredential)) {
            history.replace(getSelectedPageAfterLogin() ?? Routings.MainPage);
            return;
        }

        setIsLoading(false);
    };

    const cloudLogout = async () => {
        await logout();
        setCloudAccount(getCloudAccount());
    };

    const onDemo = () => {
        setDemo(true);
        history.replace(Routings.MainPage);
    };

    return (
        <div className={styles.mainContainer}>
            <img className={styles.backgroundImg} src={backgroundImg} />
            <div className={styles.demo}>
                <Button className={styles.demoButton} onClick={onDemo} type="primary">
                    {t('loginPage.demo')}
                </Button>
            </div>
            <img className={styles.logoImg} src={logo} />
            <div className={styles.loginContainer}>
                {isLoading && <div className={styles.backgroundLoaderContainer} />}
                {isLoading && (
                    <div className={styles.loaderContaier}>
                        <Loader type="Circles" color="#c7c7c7" height={100} width={100} />
                    </div>
                )}
                <Form onFinish={doLogin}>
                    <div className={styles.textContainer}>{t('loginPage.username')}</div>
                    <Form.Item
                        name={FormFields.username}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <Input className={styles.input} />
                    </Form.Item>
                    <div className={styles.textContainer}>{t('loginPage.password')}</div>
                    <Form.Item
                        name={FormFields.password}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <Input.Password className={styles.input} />
                    </Form.Item>
                    <Button className={styles.submitButton} type="primary" htmlType="submit">
                        {t('loginPage.login')}
                    </Button>
                </Form>
                {cloudAccount && (
                    <div className={styles.cloudContainer}>
                        <img className={styles.cloudImg} src={CloudImg} />
                        <div className={styles.cloudTextContainer}>
                            <div className={styles.activeCloudText}>{t('loginPage.activeCloudAccount')}</div>
                            <div>{cloudAccount?.idTokenClaims?.username as string}</div>
                        </div>
                        <Button
                            onClick={cloudLogout}
                            type="primary"
                            icon={<CloseOutlined />}
                            className={styles.cloudLogout}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginPage;
