import UniversalCookie from 'universal-cookie';
import { ControlsSortingMode } from '../models/enums/ControlsSortingMode';
import { Cookies } from '../models/enums/Cookies';
import { Routings } from '../models/enums/Routings';
import { TabType } from '../models/enums/TabType';
import { VirtualDeviceCategory } from '../models/enums/VirtualDeviceCategory';
import { Room } from '../models/Room';
import { UserCredential } from '../models/UserCredential';

const cookies = new UniversalCookie();

export const getUserCredential = (): UserCredential => {
    return cookies.get(Cookies.UserCredential);
};

export const saveUserCredential = (userCredential: UserCredential): void => {
    cookies.set(Cookies.UserCredential, userCredential);
};

export const removeUserCredential = (): void => {
    cookies.remove(Cookies.UserCredential);
};

export const getSavedSelectedTab = (): { tab: TabType; room: Room; category: VirtualDeviceCategory } => {
    return cookies.get(Cookies.SelectedTab);
};

export const saveSelectedTab = (tab: TabType, room?: Room, category?: VirtualDeviceCategory): void => {
    cookies.set(Cookies.SelectedTab, { tab: tab, room: room, category: category });
};

export const getSavedSelectedFloor = (): { datapointId: number; floorId: string } => {
    return cookies.get(Cookies.SelectedFloor);
};

export const saveSelectedFloor = (datapointId: number, floorId: string): void => {
    cookies.set(Cookies.SelectedFloor, { datapointId: datapointId, floorId: floorId });
};

export const saveSelectedPageAfterLogin = (page: Routings): void => {
    cookies.set(Cookies.SelectedPageAfterLogin, page);
};

export const getSelectedPageAfterLogin = (): Routings => {
    return cookies.get(Cookies.SelectedPageAfterLogin);
};

export const saveControlsSortingMode = (page: ControlsSortingMode): void => {
    cookies.set(Cookies.ControlsSortingMode, page);
};

export const getControlsSortingMode = (): ControlsSortingMode => {
    return cookies.get(Cookies.ControlsSortingMode);
};
