import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getBooleanDatapointValue } from '../../../../../helpers/DatapointHelper';
import { pushButtonDatapoint } from '../../../../../helpers/HttpMethods';
import { getVirtualDatapointNameTranslation } from '../../../../../helpers/StringHelper';
import { AlphatronicConfig, AlphatronicGroup } from '../../../../../models/AlphatronicConfig';
import { Datapoint } from '../../../../../models/Datapoint';
import { VirtualDevice } from '../../../../../models/VirtualDevice';
import { VirtualDeviceCategorySettings } from '../../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../../../../models/enums/DatapointType';
import CustomButton from '../../components/custom-button/CustomButton';
import EnterPinModal from '../EnterPinModal/EnterPinModal';
import styles from './AlphatronicGroupView.module.scss';

type Props = {
    config: AlphatronicConfig;
    group: AlphatronicGroup;
    virtualDevice: VirtualDevice;
};

const AlphatronicGroupView = (props: Props): JSX.Element => {
    const { virtualDevice, group, config } = props;
    const [codeModal, setCodeModal] = useState<Datapoint>();
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);

    const labels = useMemo(
        () =>
            virtualDevice.datapoints
                .filter(
                    (x) => (x.type == DatapointType.Number && x.writeprotect) || x.type == DatapointType.StatusLight,
                )
                .filter((x) => group.Datapoints.includes(x.id))
                .filter((x) => !(config.HideWhenFalse.includes(x.id) && !getBooleanDatapointValue(x))),
        [virtualDevice, group],
    );
    const buttons = useMemo(
        () =>
            virtualDevice.datapoints
                .filter((x) => x.type == DatapointType.Button)
                .filter((x) => group.Datapoints.includes(x.id)),
        [virtualDevice, group],
    );

    const onButtonClicked = (dp: Datapoint) => {
        if (config.CodeRequired.includes(dp.id)) {
            setCodeModal(dp);
            return;
        }

        pushButtonDatapoint(dp);
    };

    if (labels.length === 0 && buttons.length === 0) {
        return <></>;
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.groupName}>{group.Name}</div>
            {labels &&
                labels.map((x) => (
                    <div key={x.id} className={classNames(styles.titleValue, styles.datapoint)}>
                        <div className={styles.dpName}>
                            {getVirtualDatapointNameTranslation('virtualDatapointsNames.' + x.name) ?? x.name}
                        </div>
                        <div>{x?.value?.toString()}</div>
                    </div>
                ))}
            <div className={styles.span} />
            {buttons &&
                buttons.map((x) => (
                    <div key={x.id} className={styles.button}>
                        <CustomButton
                            onClick={() => onButtonClicked(x)}
                            title={getVirtualDatapointNameTranslation('virtualDatapointsNames.' + x.name) ?? x.name}
                        />
                    </div>
                ))}
            {codeModal && (
                <EnterPinModal
                    color={category?.color ?? 'black'}
                    datapoint={codeModal}
                    onCancel={() => setCodeModal(undefined)}
                />
            )}
            {group.Groups.length > 0 &&
                group.Groups.map((z, index) => <AlphatronicGroupView key={z.Name + index} {...props} group={z} />)}
        </div>
    );
};

export default AlphatronicGroupView;
