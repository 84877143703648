import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';
import { getSavedSelectedTab } from '../helpers/CookieHelper';
import { ThemeType } from '../helpers/useTheme';
import { Device } from '../models/Device';
import { Ios } from '../models/Ios';
import { Room } from '../models/Room';
import { SetupInfo } from '../models/SetupInfo';
import { VirtualDevice } from '../models/VirtualDevice';
import { WebsocketMessage } from '../models/WebsocketMessage';
import { TabType } from '../models/enums/TabType';
import { VirtualDeviceCategory } from '../models/enums/VirtualDeviceCategory';

interface Floor {
    datapointId?: number;
    floorId?: string;
}
interface Tab {
    room?: Room;
    category?: VirtualDeviceCategory;
    type: TabType;
}
interface GlobalSettingsState {
    SetupInfo?: SetupInfo;
    SelectedTab?: Tab;
    SelectedFloor?: Floor;
    AdvancedPropertyRoom?: number;
    LastWebsocketMessage?: WebsocketMessage;
    theme: ThemeType;
}

// Define the initial state using that type
const initialState: GlobalSettingsState = {
    SetupInfo: undefined,
    SelectedTab: {
        type: getSavedSelectedTab()?.tab ?? TabType.Favourite,
        room: getSavedSelectedTab()?.room,
        category: getSavedSelectedTab()?.category,
    },
    AdvancedPropertyRoom: 0,
    LastWebsocketMessage: undefined,
    theme: ThemeType.light,
};

export const globalSettings = createSlice({
    name: 'globalSettings',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setSetupInfo: (state, action) => {
            state.SetupInfo = action.payload;
        },
        setDatapointValue: (state, action) => {
            const virtualDevice = state.SetupInfo?.objects.items.find((x) =>
                x.datapoints.find((y) => y.id == action.payload.id),
            );

            if (virtualDevice) {
                const datapoint = virtualDevice.datapoints.find((y) => y.id == action.payload.id);

                if (!datapoint) {
                    return;
                }

                try {
                    const parsedData = JSON.parse(action.payload.value);

                    if (parsedData?.Scenes) {
                        datapoint.Scenes = parsedData?.Scenes;
                        return;
                    }

                    if (parsedData?.TimeProgramList) {
                        if (datapoint.TimeProgram) {
                            datapoint.TimeProgram.TimeProgramList = parsedData?.TimeProgramList;
                        } else {
                            datapoint.TimeProgramList = parsedData?.TimeProgramList;
                        }
                        return;
                    }

                    if (parsedData?.CalendarSwitchPoints) {
                        datapoint.TimeProgram.CalendarSwitchPoints = parsedData?.CalendarSwitchPoints;
                        return;
                    }

                    if (parsedData?.Alarms) {
                        datapoint.TimeProgram.Alarms = parsedData?.Alarms;
                        return;
                    }

                    if (parsedData?.Presences) {
                        datapoint.Presences = parsedData?.Presences;
                        return;
                    }

                    if (parsedData?.Buttons) {
                        datapoint.CominghomeConfig.Buttons = parsedData?.Buttons;
                        return;
                    }

                    if (parsedData?.Latitude) {
                        datapoint.AstroConfig.Latitude = parsedData?.Latitude;
                        datapoint.AstroConfig.Longitude = parsedData?.Longitude;
                        datapoint.AstroConfig.FunctionBlocks = parsedData?.FunctionBlocks;
                        return;
                    }

                    if (parsedData?.Configs) {
                        datapoint.Configs = parsedData?.Configs;
                        datapoint.Modes = parsedData?.Modes;
                        return;
                    }

                    if (parsedData?.Devices) {
                        datapoint.EnergyManagementConfig.Devices = parsedData?.Devices;
                        return;
                    }
                } catch {}

                datapoint.value = action.payload.value;
            }
        },
        setMonitorValue: (state, action) => {
            const ios = state.SetupInfo?.ios?.find((x) => x.id == action.payload.ioid);

            if (ios) {
                ios.value = action.payload.value.value;
            }
        },
        setSelectedTab: (state, action) => {
            state.SelectedTab = action.payload;
        },
        setSelectedFloor: (state, action) => {
            state.SelectedFloor = action.payload;
        },
        setAdvancedPropertyRoom: (state, action) => {
            state.AdvancedPropertyRoom = action.payload;
        },
        setVirtualDeviceProps: (state, action) => {
            const virtualDevice = state.SetupInfo?.objects?.items?.find((x) => x.id === action.payload.objId);

            if (virtualDevice) {
                virtualDevice.favourite = action.payload.favourite;
                virtualDevice.ranking = action.payload.ranking;
            }
        },
        setLastWebsocketMessage: (state, action) => {
            state.LastWebsocketMessage = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
    },
});

export const {
    setSetupInfo,
    setDatapointValue,
    setSelectedTab,
    setMonitorValue,
    setSelectedFloor,
    setAdvancedPropertyRoom,
    setVirtualDeviceProps,
    setLastWebsocketMessage,
    setTheme,
} = globalSettings.actions;

export const getSetupInfo = (state: RootState): SetupInfo | undefined => state.globalSettings.SetupInfo;
export const getVirtualDevices = (state: RootState): VirtualDevice[] | undefined =>
    state.globalSettings.SetupInfo?.objects?.items;
export const getRooms = (state: RootState): Room[] | undefined => state.globalSettings.SetupInfo?.rooms;
export const getIos = (state: RootState): Ios[] | undefined => state.globalSettings.SetupInfo?.ios;
export const getDevices = (state: RootState): Device[] | undefined => state.globalSettings.SetupInfo?.devices;
export const getToken = (state: RootState): string | undefined => state.globalSettings.SetupInfo?.token?.token;
export const getSelectedFloor = (state: RootState): Floor | undefined => state.globalSettings.SelectedFloor;
export const getSelectedTab = (state: RootState): Tab | undefined => state.globalSettings.SelectedTab;
export const getAdvancedPropertyRoom = (state: RootState): number | undefined =>
    state.globalSettings.AdvancedPropertyRoom;
export const getLastWebsocketMessage = (state: RootState): WebsocketMessage | undefined =>
    state.globalSettings.LastWebsocketMessage;
export const getTheme = (state: RootState): ThemeType => state.globalSettings.theme;

export default globalSettings.reducer;
