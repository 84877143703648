import { Input, Modal } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSetupInfo } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { addUser } from '../../helpers/HttpMethods';
import { encryptRSA } from '../../helpers/HttpRSAHelper';
import { getValidationNameError, isEmpty, validatePassword } from '../../helpers/StringHelper';
import styles from './AddUserModal.module.scss';

interface Props {
    onFinished?: () => void;
    onCancel?: () => void;
}

const AddUserModal = (props: Props): JSX.Element => {
    const { onFinished, onCancel } = props;
    const { t } = useTranslation();

    const setupInfo = useAppSelector(getSetupInfo);
    const users = setupInfo?.users;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const usernameError = useMemo(() => getValidationNameError(username), [username]);

    const isError =
        isEmpty(username) ||
        users?.some((x) => x.name === username) ||
        isEmpty(password) ||
        !validatePassword(password) ||
        !!usernameError;

    const onAdd = async () => {
        if (!users) {
            return;
        }
        try {
            setIsLoading(true);

            const result = await addUser({
                name: username,
                password: await encryptRSA(password),
                deletable: true,
                id: users?.length + 2,
                isadmin: false,
            });

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }

            onFinished?.();
        } catch {
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    return (
        <Modal
            width={300}
            title={t('addUserModal.addUser')}
            open={true}
            cancelText={t('general.cancel')}
            okText={t('general.add')}
            onOk={onAdd}
            onCancel={onCancel}
            okButtonProps={{ loading: isLoading, disabled: isError }}
            style={{ top: 200 }}
            closable={false}
            maskClosable={false}
        >
            <Input
                status={
                    isEmpty(username) || users?.some((x) => x.name === username) || usernameError ? 'error' : undefined
                }
                onChange={(value) => {
                    setUsername(value.currentTarget.value);
                }}
                value={username}
                placeholder={t('usersConfiguration.userName')}
            />
            {users?.some((x) => x.name === username) && (
                <div className={styles.nameError}>{t('errors.NameAlreadyExist')}</div>
            )}
            {isEmpty(username) && <div className={styles.nameError}>{t('errors.fieldCannotBeEmpty')}</div>}
            {!isEmpty(username) && usernameError && <div className={styles.nameError}>{usernameError}</div>}
            <Input
                status={isEmpty(password) ? 'error' : undefined}
                onChange={(value) => {
                    setPassword(value.currentTarget.value);
                }}
                value={password}
                className={styles.passwordInput}
                placeholder={t('usersConfiguration.userPassword')}
            />
            {isEmpty(password) && <div className={styles.nameError}>{t('errors.fieldCannotBeEmpty')}</div>}
            {!isEmpty(password) && !validatePassword(password) && (
                <div className={styles.nameError}>{t('errors.passwordNotValid')}</div>
            )}
        </Modal>
    );
};

export default AddUserModal;
