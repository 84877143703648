import { useTranslation } from 'react-i18next';
import { EnergyManagementDevice } from '../../../models/EnergyManagementDevice';
import SummaryDevice from './SummaryDevice/SummaryDevice';

import styles from './SummaryTab.module.scss';

type Props = {
    functionBlocks: EnergyManagementDevice[];
};

const SummaryTab = ({ functionBlocks }: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.header}>{t('general.name')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.priority')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.level')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.mode')}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.minPower') + ' (kW)'}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.maxPower') + ' (kW)'}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.maxEnergy') + ' (kWh)'}</div>
                <div className={styles.header}>{t('energyManagementConfiguration.preparationTime') + ' (min)'}</div>
                <div className={styles.lastHeader}>{t('energyManagementConfiguration.minRuntime') + ' (min)'}</div>
            </div>
            {functionBlocks.map((block, index) => (
                <SummaryDevice key={index} device={block} />
            ))}
        </div>
    );
};

export default SummaryTab;
