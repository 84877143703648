import { useMemo } from 'react';
import { EnergyManagementDevice } from '../../../../models/EnergyManagementDevice';

import { useTranslation } from 'react-i18next';
import styles from './SummaryDevice.module.scss';

type Props = {
    device: EnergyManagementDevice;
};

const SummaryDevice = ({ device }: Props): JSX.Element => {
    const { t } = useTranslation();
    const sortedPrio = useMemo(() => [...device.Priorities].sort((x, y) => x.Level - y.Level), [device]);
    const sortedDevices = useMemo(
        () =>
            sortedPrio
                .map((sprio, index) => ({
                    ...sprio,
                    maxLevel: sortedPrio.length - 1 === index ? '+' : ' - ' + sortedPrio[index + 1].Level,
                }))
                .sort((x, y) => (x.Priority ?? 0) - (y.Priority ?? 0)),
        [sortedPrio],
    );

    const config = useMemo(
        () => [
            {
                value: 0,
                label: t('general.off'),
            },
            {
                value: 1,
                label: t('general.on'),
            },
            {
                value: 2,
                label: t('general.solar'),
            },
            {
                value: 3,
                label: t('general.solarAndLowPrice'),
            },
        ],
        [],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.nameWrapper}>{device.Name}</div>
            <div className={styles.levelsWrapper}>
                {sortedDevices.map((block, index) => (
                    <div className={styles.blockWrapper} key={index}>
                        <div className={styles.propWrapper}>{block.Priority ?? '-'}</div>
                        <div className={styles.propWrapper}>{block.Level + block.maxLevel}</div>
                        <div className={styles.propLastWrapper}>
                            {config.find((x) => x.value === block.Mode)?.label}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.nameWrapper}>{device.MinPower ?? '-'}</div>
            <div className={styles.nameWrapper}>{device.MaxPower ?? '-'}</div>
            <div className={styles.nameWrapper}>{device.MaxEnergy ?? '-'}</div>
            <div className={styles.nameWrapper}>{device.PreparationTime ?? '-'}</div>
            <div className={styles.nameLastWrapper}>{device.MinRuntime ?? '-'}</div>
        </div>
    );
};

export default SummaryDevice;
