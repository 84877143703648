import { Select } from 'antd';
import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import OperationModeConfigurationModal from '../../../../components/operation-mode-configuration/OperationModeConfigurationModal';
import { getBooleanDatapointValue, getNumberDatapointValue } from '../../../../helpers/DatapointHelper';
import { updateDatapointValue } from '../../../../helpers/HttpMethods';
import { Datapoint } from '../../../../models/Datapoint';
import { OpeartionModeConfigType } from '../../../../models/OperationModeConfig';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { ControllersProps } from '../ControllersProps';
import ControlHeader from '../components/control-header/ControlHeader';
import CustomRange from '../components/custom-slider/CustomRange';
import styles from './OperationalModeSelector.module.scss';

const OperationalModeSelector = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, order, forceShowAll } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;
    const sceneSelection = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.SceneSelection);
    const modesConfig = virtualDevice?.datapoints?.find((x) => x.type == DatapointType.VisualizationBlockConfig);
    const lightsColorDatapoints = virtualDevice?.datapoints?.filter((x) => x.name.includes('opMode'));
    const modesOptions = modesConfig?.Modes?.map((x) => ({ label: x.Name, value: x.ID }));
    const configs = modesConfig?.Configs;

    const [configModalVisible, setConfigModalVisible] = useState(false);
    const [datapointsVisible, setDatapointsVisible] = useState(false);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    const getConfigForName = (name: string) => {
        return configs?.find((x) => x.Description === name);
    };

    return (
        <div className={classNames(styles.mainContainer)} style={{ zIndex: order, border: border }}>
            <ControlHeader
                virtualDevice={virtualDevice}
                room={room}
                onEditButtonClick={() => setConfigModalVisible(true)}
            />
            {(modesOptions?.length ?? 0) > 1 && (
                <Select
                    value={getNumberDatapointValue(sceneSelection)}
                    style={{ width: '100%', marginTop: 10, marginLeft: 5, marginBottom: 5 }}
                    options={modesOptions}
                    onChange={(v) => onDatapointValueChanged(v, sceneSelection)}
                />
            )}
            {lightsColorDatapoints?.slice(0, forceShowAll ? lightsColorDatapoints.length : 4).map((x) => (
                <div key={x.id} className={classNames(styles.titleValue, styles.datapointTop)}>
                    <div className={styles.customName}>{x.customname}</div>
                    {getConfigForName(x.customname)?.Type === OpeartionModeConfigType.Dimmer && (
                        <>
                            <CustomRange
                                disabled={getConfigForName(x.customname)?.ReadOnly}
                                step={Number(getConfigForName(x.customname)?.Step ?? 1)}
                                min={Number(getConfigForName(x.customname)?.Minimum ?? 0)}
                                max={Number(getConfigForName(x.customname)?.Maximum ?? 100)}
                                unit={getConfigForName(x.customname)?.Unit}
                                value={
                                    x?.value &&
                                    Number(x?.value) >= Number(Number(getConfigForName(x.customname)?.Minimum) ?? 0)
                                        ? Number(x?.value)
                                        : Number(Number(getConfigForName(x.customname)?.Minimum) ?? 0)
                                }
                                minTrackColor={'#ffffff'}
                                onValueChanged={(value) => onDatapointValueChanged(value, x)}
                            />
                            <div className={styles.sliderValue}>
                                {`${
                                    x?.value && Number(x?.value) >= Number(x?.range[0] ?? 0)
                                        ? Number(x?.value)
                                        : Number(x?.range[0] ?? 0)
                                } ${getConfigForName(x.customname)?.Unit ?? ''}`}
                            </div>
                        </>
                    )}
                    {getConfigForName(x.customname)?.Type === OpeartionModeConfigType.Switch && (
                        <Switch
                            disabled={getConfigForName(x.customname)?.ReadOnly}
                            height={20}
                            width={35}
                            className={styles.switch}
                            onChange={(checked: boolean) => onDatapointValueChanged(checked, x)}
                            checked={getBooleanDatapointValue(x)}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={category?.color}
                            activeBoxShadow={`0 0 0px 0px ${category?.color}`}
                        />
                    )}
                    {getConfigForName(x.customname)?.Type === OpeartionModeConfigType.Dropdown && (
                        <Select
                            disabled={
                                getConfigForName(x.customname)?.ReadOnly ||
                                !getConfigForName(x.customname)?.DropdownList
                            }
                            value={+x.value}
                            style={{ minWidth: 80 }}
                            onChange={(v) => onDatapointValueChanged(v, x)}
                            options={getConfigForName(x.customname)?.DropdownList?.map((x) => ({
                                label: x.Name,
                                value: Number(x.Value),
                            }))}
                        />
                    )}
                </div>
            ))}
            {lightsColorDatapoints.length > 4 && !forceShowAll && (
                <div
                    className={styles.dots}
                    onMouseEnter={() => {
                        setDatapointsVisible(true);
                    }}
                    onMouseLeave={() => setDatapointsVisible(false)}
                >
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    <span className={styles.dot} />
                    {datapointsVisible && (
                        <div
                            className={styles.datapointsList}
                            style={{
                                zIndex: order,
                                borderBottom: border,
                                borderLeft: border,
                                borderRight: border,
                            }}
                        >
                            {lightsColorDatapoints?.slice(4).map((x) => (
                                <div key={x.id} className={classNames(styles.titleValue, styles.datapoint)}>
                                    <div className={styles.customName}>{x.customname}</div>
                                    {getConfigForName(x.customname)?.Type === OpeartionModeConfigType.Dimmer && (
                                        <>
                                            <CustomRange
                                                disabled={getConfigForName(x.customname)?.ReadOnly}
                                                step={Number(getConfigForName(x.customname)?.Step ?? 1)}
                                                min={Number(getConfigForName(x.customname)?.Minimum ?? 0)}
                                                max={Number(getConfigForName(x.customname)?.Maximum ?? 100)}
                                                unit={getConfigForName(x.customname)?.Unit}
                                                value={
                                                    x?.value &&
                                                    Number(x?.value) >=
                                                        Number(Number(getConfigForName(x.customname)?.Minimum) ?? 0)
                                                        ? Number(x?.value)
                                                        : Number(Number(getConfigForName(x.customname)?.Minimum) ?? 0)
                                                }
                                                minTrackColor={'#ffffff'}
                                                onValueChanged={(value) => onDatapointValueChanged(value, x)}
                                            />
                                            <div className={styles.sliderValue}>
                                                {`${
                                                    x?.value && Number(x?.value) >= Number(x?.range[0] ?? 0)
                                                        ? Number(x?.value)
                                                        : Number(x?.range[0] ?? 0)
                                                } ${getConfigForName(x.customname)?.Unit ?? ''}`}
                                            </div>
                                        </>
                                    )}
                                    {getConfigForName(x.customname)?.Type === OpeartionModeConfigType.Switch && (
                                        <Switch
                                            disabled={getConfigForName(x.customname)?.ReadOnly}
                                            height={20}
                                            width={35}
                                            className={styles.switch}
                                            onChange={(checked: boolean) => onDatapointValueChanged(checked, x)}
                                            checked={getBooleanDatapointValue(x)}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onColor={category?.color}
                                            activeBoxShadow={`0 0 0px 0px ${category?.color}`}
                                        />
                                    )}
                                    {getConfigForName(x.customname)?.Type === OpeartionModeConfigType.Dropdown && (
                                        <Select
                                            disabled={
                                                getConfigForName(x.customname)?.ReadOnly ||
                                                !getConfigForName(x.customname)?.DropdownList
                                            }
                                            value={+x.value}
                                            style={{ minWidth: 80 }}
                                            onChange={(v) => onDatapointValueChanged(v, x)}
                                            options={getConfigForName(x.customname)?.DropdownList?.map((x) => ({
                                                label: x.Name,
                                                value: Number(x.Value),
                                            }))}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {modesConfig && configModalVisible && (
                <OperationModeConfigurationModal
                    onCloseRequested={() => setConfigModalVisible(false)}
                    virtualDevice={virtualDevice}
                    datapoint={modesConfig}
                />
            )}
        </div>
    );
};

export default OperationalModeSelector;
