import { TFunction } from 'i18next';
import { DatapointNames } from '../enums/DatapointNames';
import SleepImg from '../../images/sleep.svg';
import InHomeImg from '../../images/in_home.svg';
import AwayFromHomeImg from '../../images/away_from_home.svg';

export const DatapointSettings = (t: TFunction): { name?: string; translation?: string; img?: string }[] => [
    {
        name: DatapointNames.VirtualSwitch,
        translation: t('datapointNames.VirtualSwitch'),
    },
    {
        name: DatapointNames.VirtualSlider,
        translation: t('datapointNames.VirtualSlider'),
    },
    {
        name: DatapointNames.VirtualOutput,
        translation: t('datapointNames.VirtualOutput'),
    },
    {
        name: DatapointNames.VirtualSchedulerOn,
        translation: t('datapointNames.VirtualSchedulerOn'),
    },
    {
        name: DatapointNames.VirtualTrigger,
        translation: t('datapointNames.VirtualTrigger'),
    },
    {
        name: DatapointNames.VirtualLight,
        translation: t('datapointNames.VirtualLight'),
    },
    {
        name: DatapointNames.VirtualFan,
        translation: t('datapointNames.VirtualFan'),
    },
    {
        name: DatapointNames.VirtualFireAlarm,
        translation: t('datapointNames.VirtualFireAlarm'),
    },
    {
        name: DatapointNames.VirtualSmokeAlarm,
        translation: t('datapointNames.VirtualSmokeAlarm'),
    },
    {
        name: DatapointNames.VirtualWaterAlarm,
        translation: t('datapointNames.VirtualWaterAlarm'),
    },
    {
        name: DatapointNames.VirtualQuitAlarm,
        translation: t('datapointNames.VirtualQuitAlarm'),
    },
    {
        name: DatapointNames.VirtualAlarmSystemIsArmed,
        translation: t('datapointNames.VirtualAlarmSystemIsArmed'),
    },
    {
        name: DatapointNames.VirtualArmedWithoutPresenceDetectors,
        translation: t('datapointNames.VirtualArmedWithoutPresenceDetectors'),
    },
    {
        name: DatapointNames.VirtualAcivationCountdown,
        translation: t('datapointNames.VirtualAcivationCountdown'),
    },
    {
        name: DatapointNames.VirtualIsAlarm,
        translation: t('datapointNames.VirtualIsAlarm'),
    },
    {
        name: DatapointNames.VirtualArmAlarmSystem,
        translation: t('datapointNames.VirtualArmAlarmSystem'),
    },
    {
        name: DatapointNames.VirtualArmAlarmSystemDelayed,
        translation: t('datapointNames.VirtualArmAlarmSystemDelayed'),
    },
    {
        name: DatapointNames.VirtualArmWithoutPresenceDetectors,
        translation: t('datapointNames.VirtualArmWithoutPresenceDetectors'),
    },
    {
        name: DatapointNames.VirtualDisarmAlarmSystem,
        translation: t('datapointNames.VirtualDisarmAlarmSystem'),
    },
    {
        name: DatapointNames.VirtualIsSnooze,
        translation: t('datapointNames.VirtualIsSnooze'),
    },
    {
        name: DatapointNames.VirtualSnoozeIntervalDuration,
        translation: t('datapointNames.VirtualSnoozeIntervalDuration'),
    },
    {
        name: DatapointNames.VirtualQuitAlarmClock,
        translation: t('datapointNames.VirtualQuitAlarmClock'),
    },
    {
        name: DatapointNames.VirtualStartSnoozeTimer,
        translation: t('datapointNames.VirtualStartSnoozeTimer'),
    },
    {
        name: DatapointNames.VirtualTriggerOpen,
        translation: t('datapointNames.VirtualTriggerOpen'),
    },
    {
        name: DatapointNames.VirtualTriggerClose,
        translation: t('datapointNames.VirtualTriggerClose'),
    },
    {
        name: DatapointNames.VirtualStop,
        translation: t('datapointNames.VirtualStop'),
    },
    {
        name: DatapointNames.VirtualPosition,
        translation: t('datapointNames.VirtualPosition'),
    },
    {
        name: DatapointNames.VirtualCountdown,
        translation: t('datapointNames.VirtualCountdown'),
    },
    {
        name: DatapointNames.VirtualStartTimer,
        translation: t('datapointNames.VirtualStartTimer'),
    },
    {
        name: DatapointNames.VirtualBlindsPosition,
        translation: t('datapointNames.VirtualBlindsPosition'),
    },
    {
        name: DatapointNames.VirtualSlatsPosition,
        translation: t('datapointNames.VirtualSlatsPosition'),
    },
    {
        name: DatapointNames.VirtualSetBlinds,
        translation: t('datapointNames.VirtualSetBlinds'),
    },
    {
        name: DatapointNames.VirtualSetSlats,
        translation: t('datapointNames.VirtualSetSlats'),
    },
    {
        name: DatapointNames.VirtualShadingOn,
        translation: t('datapointNames.VirtualShadingOn'),
    },
    {
        name: DatapointNames.VirtualIsRoomTemperature,
        translation: t('datapointNames.VirtualIsRoomTemperature'),
    },
    {
        name: DatapointNames.VirtualOperationMode,
        translation: t('datapointNames.VirtualOperationMode'),
    },
    {
        name: DatapointNames.VirtualManualModeHeating,
        translation: t('datapointNames.VirtualManualModeHeating'),
    },
    {
        name: DatapointNames.VirtualStandbyTemperature,
        translation: t('datapointNames.VirtualStandbyTemperature'),
    },
    {
        name: DatapointNames.VirtualManualModeCooling,
        translation: t('datapointNames.VirtualManualModeCooling'),
    },
    {
        name: DatapointNames.VirtualSetpointManualModeCooling,
        translation: t('datapointNames.VirtualSetpointManualModeCooling'),
    },
    {
        name: DatapointNames.VirtualSetpointManualModeHeating,
        translation: t('datapointNames.VirtualSetpointManualModeHeating'),
    },
    {
        name: DatapointNames.VirtualLowBatteryLimit,
        translation: t('datapointNames.VirtualLowBatteryLimit'),
    },
    {
        name: DatapointNames.VirtualComingHome,
        translation: t('datapointNames.VirtualComingHome'),
        img: InHomeImg,
    },
    {
        name: DatapointNames.VirtualSleep,
        translation: t('datapointNames.VirtualSleep'),
        img: SleepImg,
    },
    {
        name: DatapointNames.VirtualLeavingHome,
        translation: t('datapointNames.VirtualLeavingHome'),
        img: AwayFromHomeImg,
    },
    {
        name: DatapointNames.VirtualNumberOfOpen,
        translation: t('datapointNames.VirtualNumberOfOpen'),
    },
    {
        name: DatapointNames.VirtualNumberOfClosed,
        translation: t('datapointNames.VirtualNumberOfClosed'),
    },
    {
        name: DatapointNames.VirtualHourEnergy,
        translation: t('datapointNames.VirtualHourEnergy'),
    },
    {
        name: DatapointNames.VirtualDayEnergy,
        translation: t('datapointNames.VirtualDayEnergy'),
    },
    {
        name: DatapointNames.VirtualMonthEnergy,
        translation: t('datapointNames.VirtualMonthEnergy'),
    },
    {
        name: DatapointNames.VirtualYearEnergy,
        translation: t('datapointNames.VirtualYearEnergy'),
    },
    {
        name: DatapointNames.VirtualReset,
        translation: t('datapointNames.VirtualReset'),
    },
    {
        name: DatapointNames.VirtualTriggerDown,
        translation: t('datapointNames.VirtualTriggerDown'),
    },
    {
        name: DatapointNames.VirtualTriggerUp,
        translation: t('datapointNames.VirtualTriggerUp'),
    },
    {
        name: DatapointNames.VirtualShadowing,
        translation: t('datapointNames.VirtualShadowing'),
    },
    {
        name: DatapointNames.VirtualCentralBlindsStop,
        translation: t('datapointNames.VirtualCentralBlindsStop'),
    },
];
