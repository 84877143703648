import { Input, Modal } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TbNumber0,
    TbNumber1,
    TbNumber2,
    TbNumber3,
    TbNumber4,
    TbNumber5,
    TbNumber6,
    TbNumber7,
    TbNumber8,
    TbNumber9,
} from 'react-icons/tb';
import { pushButtonDatapoint } from '../../../../../helpers/HttpMethods';
import { encryptRSA } from '../../../../../helpers/HttpRSAHelper';
import { Datapoint } from '../../../../../models/Datapoint';
import styles from './EnterPinModal.module.scss';

interface Props {
    datapoint: Datapoint;
    onCancel: () => void;
    color: string;
}

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const numberIcons: Record<number, JSX.Element> = {
    0: <TbNumber0 size={18} />,
    1: <TbNumber1 size={18} />,
    2: <TbNumber2 size={18} />,
    3: <TbNumber3 size={18} />,
    4: <TbNumber4 size={18} />,
    5: <TbNumber5 size={18} />,
    6: <TbNumber6 size={18} />,
    7: <TbNumber7 size={18} />,
    8: <TbNumber8 size={18} />,
    9: <TbNumber9 size={18} />,
};

const EnterPinModal = ({ datapoint, onCancel, color }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [code, setCode] = useState<string>();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const onOk = async () => {
        if (!code) {
            return;
        }

        setLoading(true);
        const encryptedCode = await encryptRSA(code);
        const result = await pushButtonDatapoint(datapoint, encryptedCode);

        if (result.status === StatusCodes.OK) {
            onCancel();
            setLoading(false);
            return;
        }

        setLoading(false);
    };

    const onButtonPress = (n: number) => {
        if (code && code.toString().length === 6) {
            return;
        }

        setCode((prev) => (prev ? prev + n.toString() : n.toString()));
    };

    return (
        <Modal
            width="fit-content"
            title={t('enterPinModal.codeRequired')}
            open={true}
            cancelText={t('general.cancel')}
            okText={'OK'}
            onOk={onOk}
            onCancel={onCancel}
            closable={!loading}
            maskClosable={!loading}
            cancelButtonProps={{ disabled: loading }}
            okButtonProps={{ danger: true, disabled: !code, loading: loading }}
            style={{ top: 150 }}
        >
            <div className={styles.mainWrapper}>
                <Input.Password
                    size="large"
                    min={0}
                    step={1}
                    autoComplete="new-password"
                    maxLength={6}
                    autoFocus={false}
                    visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                    value={code ?? ''}
                    onChange={(v) => {
                        const currentValue = v.currentTarget.value;

                        if (currentValue.length === 0 || !currentValue) {
                            setCode(undefined);
                        }

                        if (!/[0-9]/.test(currentValue)) {
                            v.preventDefault();
                            return;
                        }

                        setCode(currentValue);
                    }}
                />
                <div className={styles.buttonsWrapper}>
                    {numbers.map((n) => (
                        <div
                            onClick={() => onButtonPress(n)}
                            className={styles.roundButton}
                            key={n}
                            style={{ borderColor: color }}
                        >
                            {numberIcons[n]}
                        </div>
                    ))}
                </div>
                <div onClick={() => onButtonPress(0)} className={styles.roundButton} style={{ borderColor: color }}>
                    {numberIcons[0]}
                </div>
            </div>
        </Modal>
    );
};

export default EnterPinModal;
