import { Button, Card, Select, Spin } from 'antd';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSetupInfo, setSetupInfo } from '../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import AddUserModal from '../components/add-user-modal/AddUserModal';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import SingleInputModal from '../components/single-input-modal/SingleInputModal';
import YesNoModal from '../components/yes-no-modal/YesNoModal';
import { getUserCredential, removeUserCredential } from '../helpers/CookieHelper';
import {
    addUserGroup,
    deleteUser,
    deleteUserGroup,
    editAssignement,
    editUser,
    editUserGroups,
    fetchAssignments,
    fetchSetupInfo,
    fetchUserGroups,
} from '../helpers/HttpMethods';
import { encryptRSA } from '../helpers/HttpRSAHelper';
import { Assignment } from '../models/Assignment';
import { Routings } from '../models/enums/Routings';
import { Room } from '../models/Room';
import { User } from '../models/User';
import { UserGroup } from '../models/UserGroup';
import { VirtualDevice } from '../models/VirtualDevice';
import styles from './UsersConfiguration.module.scss';

const UsersConfigurationPage = (): JSX.Element => {
    const setupInfo = useAppSelector(getSetupInfo);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const users = setupInfo?.users;
    const loggedUser = getUserCredential();

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isUsergroupsEdited, setIsUsergroupsEdited] = useState(false);
    const [isAssignementsEdited, setIsAssignementsEdited] = useState(false);
    const [groups, setGroups] = useState<UserGroup[]>();
    const [groupToRemove, setGroupToRemove] = useState<UserGroup>();
    const [addGroupVisible, setAddGroupVisible] = useState(false);
    const [groupToEdit, setGroupToEdit] = useState<UserGroup>();
    const [userToRemove, setUserToRemove] = useState<User>();
    const [addUserVisible, setAddUserVisible] = useState(false);
    const [userToEdit, setUserToEdit] = useState<User>();
    const [userToPasswordEdit, setUserToPasswordEdit] = useState<User>();

    const [assignements, setAssignements] = useState<Assignment[]>();

    const getGroups = async () => {
        const result = await fetchUserGroups();

        if (result.status !== StatusCodes.OK) {
            removeUserCredential();
            history.replace(Routings.LoginPage);
            return;
        }

        setGroups(result.data);
        setIsUsergroupsEdited(false);
    };

    const getAssignements = async () => {
        const result = await fetchAssignments();

        if (result.status !== StatusCodes.OK) {
            removeUserCredential();
            history.replace(Routings.LoginPage);
            return;
        }

        setAssignements(result.data);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (!setupInfo) {
                await getNewSetupInfo();
            }

            await getGroups();
            await getAssignements();

            setIsLoading(false);
        })();
    }, []);

    const getNewSetupInfo = async () => {
        if (!loggedUser) {
            history.replace(Routings.LoginPage);
            return;
        }

        const result = await fetchSetupInfo();

        if (result.status !== StatusCodes.OK) {
            removeUserCredential();
            history.replace(Routings.LoginPage);
            return;
        }

        dispatch(setSetupInfo(result));
    };

    const onUsersChanged = (ids: number[], group: UserGroup) => {
        setGroups((prev) => prev?.map((x) => (x.id === group.id ? { ...x, user_ids: ids } : x)));
        setIsUsergroupsEdited(true);
    };

    const onAssignementChanged = (usersIds: number[], groupIds: number[], assignment: Assignment) => {
        setAssignements((prev) =>
            prev?.map((x) =>
                x.objectid === assignment.objectid ? { ...x, userids: usersIds, usergroupids: groupIds } : x,
            ),
        );
        setIsAssignementsEdited(true);
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const saveUserGroups = async () => {
        try {
            if (!groups) {
                return;
            }
            setIsUpdating(true);

            const result = await editUserGroups(groups);

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }
            getGroups();
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onRemoveGroup = async () => {
        try {
            if (!groups || !groupToRemove) {
                return;
            }
            setIsUpdating(true);

            const result = await deleteUserGroup(groupToRemove);

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }

            await getGroups();
            getAssignements();
            setGroupToRemove(undefined);
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onAddGroup = async (groupName: string) => {
        try {
            setIsUpdating(true);

            const result = await addUserGroup({
                name: groupName,
                deletable: false,
                user_ids: [],
                id: (groups?.length ?? 0) + 2,
            });

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }

            setAddGroupVisible(false);
            await getGroups();
            getAssignements();
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditGroupName = async (groupName: string, group: UserGroup) => {
        try {
            if (!groups) {
                return;
            }
            setIsUpdating(true);

            const result = await editUserGroups(
                groups?.map((x) => (x.id === group.id ? { ...x, name: groupName } : x)),
            );

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }

            setGroupToEdit(undefined);
            getGroups();
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditUserName = async (userName: string, user: User) => {
        try {
            setIsUpdating(true);

            const result = await editUser({ ...user, name: userName });

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }
            await getNewSetupInfo();
            setUserToEdit(undefined);
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditUserPassword = async (newPassword: string, user: User) => {
        try {
            setIsUpdating(true);

            const result = await editUser({ ...user, password: await encryptRSA(newPassword) });

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }
            toast.success(t('general.passwordChanged'));
            setUserToPasswordEdit(undefined);
        } catch (e) {
            console.log(e);
        } finally {
            setIsUpdating(false);
        }
    };

    const onRemoveUser = async () => {
        try {
            if (!userToRemove) {
                return;
            }

            setIsUpdating(true);

            const result = await deleteUser(userToRemove);

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }

            await getNewSetupInfo();
            getGroups();
            getAssignements();
            setUserToRemove(undefined);
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onAddFinished = async () => {
        setIsUpdating(true);
        setAddUserVisible(false);
        await getNewSetupInfo();
        setIsUpdating(false);
    };

    const getObjectById = (id: number): VirtualDevice | undefined => {
        return setupInfo?.objects.items.find((x) => x.id === id);
    };

    const getRoomByObjectId = (id: number): Room | undefined => {
        return setupInfo?.rooms?.find((room) => room.id === getObjectById(id)?.roomid);
    };

    const saveAssignements = async () => {
        try {
            if (!assignements) {
                return;
            }
            setIsUpdating(true);

            const result = await editAssignement(assignements);

            if (result.status !== StatusCodes.OK) {
                showError();
                return;
            }
            await getAssignements();
        } catch {
        } finally {
            setIsUpdating(false);
            setIsAssignementsEdited(false);
        }
    };

    return (
        <div className={styles.mainContainer}>
            <Header />
            {!isLoading && (
                <Container className={styles.cards}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={7}>
                            <Card className={styles.card}>
                                <div className={styles.cardHeader}>
                                    <div>{t('general.groups')}</div>
                                    {isUsergroupsEdited && (
                                        <Button
                                            onClick={saveUserGroups}
                                            type="primary"
                                            loading={isUpdating}
                                            className={styles.editButton}
                                        >
                                            {t('general.save')}
                                        </Button>
                                    )}
                                </div>
                                {groups?.map((group, index) => (
                                    <div key={index} className={styles.groupContainer}>
                                        <div className={styles.groupName}>{group.name}</div>
                                        <Select
                                            disabled={isUpdating}
                                            style={{ width: '100%' }}
                                            mode="multiple"
                                            options={users?.map((x) => ({
                                                label: x.name,
                                                value: x.id,
                                                disabled:
                                                    (group.id === 1 && x.id === 1) ||
                                                    (group.id === 1 && x.name === loggedUser.username),
                                            }))}
                                            value={group.user_ids}
                                            onChange={(ids) => onUsersChanged(ids, group)}
                                        />
                                        {group.id !== 1 && (
                                            <div className={styles.buttons}>
                                                <Button
                                                    onClick={() => setGroupToEdit(group)}
                                                    disabled={isUpdating}
                                                    className={styles.editButton}
                                                >
                                                    {t('general.edit')}
                                                </Button>

                                                <Button
                                                    onClick={() => setGroupToRemove(group)}
                                                    loading={isUpdating}
                                                    danger
                                                >
                                                    {t('general.remove')}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className={styles.addButtonContainer}>
                                    <Button
                                        disabled={isUpdating}
                                        onClick={(e) => {
                                            setAddGroupVisible(true);
                                            e.currentTarget.blur();
                                        }}
                                    >
                                        {'+'}
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={5}>
                            <Card className={styles.card}>
                                <div className={styles.cardHeader}>
                                    <div>{t('general.users')}</div>
                                </div>
                                {users?.map((user, index) => (
                                    <div key={index} className={styles.groupContainer}>
                                        <div className={styles.userName}>{user.name}</div>
                                        {user.id !== 1 && (
                                            <div className={styles.buttonContainer}>
                                                <div className={styles.buttons}>
                                                    <Button
                                                        onClick={() => setUserToEdit(user)}
                                                        disabled={isUpdating || user.name === loggedUser.username}
                                                    >
                                                        {t('general.edit')}
                                                    </Button>
                                                    <Button
                                                        onClick={() => setUserToPasswordEdit(user)}
                                                        disabled={isUpdating || user.name === loggedUser.username}
                                                        className={styles.changePasswordButton}
                                                    >
                                                        {t('general.changePassword')}
                                                    </Button>
                                                </div>
                                                <Button
                                                    className={styles.removeButton}
                                                    disabled={user.name === loggedUser.username}
                                                    onClick={() => setUserToRemove(user)}
                                                    loading={isUpdating}
                                                    danger
                                                >
                                                    {t('general.remove')}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className={styles.addButtonContainer}>
                                    <Button
                                        disabled={isUpdating}
                                        onClick={(e) => {
                                            setAddUserVisible(true);
                                            e.currentTarget.blur();
                                        }}
                                    >
                                        {'+'}
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card className={styles.card}>
                                <div className={styles.cardHeader}>
                                    <div>{t('general.functionsAssignement')}</div>
                                    {isAssignementsEdited && (
                                        <Button
                                            onClick={saveAssignements}
                                            type="primary"
                                            loading={isUpdating}
                                            className={styles.editButton}
                                        >
                                            {t('general.save')}
                                        </Button>
                                    )}
                                </div>
                                {assignements?.map((assignement, index) => (
                                    <div key={index} className={styles.groupContainer}>
                                        <div className={styles.assignementsName}>{`${assignement.objectname} | ${
                                            getRoomByObjectId(assignement.objectid)?.name ?? t('general.unassigned')
                                        }`}</div>
                                        <Select
                                            disabled={isUpdating}
                                            style={{ width: '100%' }}
                                            mode="multiple"
                                            options={users?.map((x) => ({
                                                label: x.name,
                                                value: x.id,
                                            }))}
                                            value={assignement.userids}
                                            onChange={(ids) =>
                                                onAssignementChanged(ids, assignement.usergroupids, assignement)
                                            }
                                        />
                                        <Select
                                            className={styles.functionUsersSelect}
                                            disabled={isUpdating}
                                            style={{ width: '100%' }}
                                            mode="multiple"
                                            options={groups?.map((x) => ({
                                                label: x.name,
                                                value: x.id,
                                                disabled: x.id === 1,
                                            }))}
                                            value={assignement.usergroupids}
                                            onChange={(ids) =>
                                                onAssignementChanged(assignement.userids, ids, assignement)
                                            }
                                        />
                                        {users && groups && (
                                            <Button
                                                onClick={() =>
                                                    onAssignementChanged(
                                                        users?.map((x) => x.id),
                                                        groups?.map((x) => x.id),
                                                        assignement,
                                                    )
                                                }
                                                className={styles.assigneToAllButton}
                                            >
                                                {t('general.assigneToAll')}
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )}
            {isLoading && <Spin size="large" className={styles.loading} />}
            {userToPasswordEdit && (
                <SingleInputModal
                    isLoading={isUpdating}
                    isVisible={true}
                    isPassword={true}
                    title={t('usersConfiguration.changePassword')}
                    placeholder={t('usersConfiguration.userPassword')}
                    onCancelClicked={() => setUserToPasswordEdit(undefined)}
                    onAddClicked={(value) => onEditUserPassword(value, userToPasswordEdit)}
                    yesButtonText={t('general.save')}
                />
            )}
            {userToEdit && (
                <SingleInputModal
                    isLoading={isUpdating}
                    isVisible={true}
                    doNameValidation={true}
                    defaultValue={userToEdit.name}
                    title={t('usersConfiguration.editUser')}
                    placeholder={t('usersConfiguration.userName')}
                    valueOtherThan={users?.map((x) => x.name).filter((x) => x !== userToEdit?.name)}
                    onCancelClicked={() => setUserToEdit(undefined)}
                    onAddClicked={(value) => onEditUserName(value, userToEdit)}
                    yesButtonText={t('general.save')}
                />
            )}
            {groupToEdit && (
                <SingleInputModal
                    isVisible={true}
                    doNameValidation={true}
                    defaultValue={groupToEdit.name}
                    title={t('usersConfiguration.editUsergroup')}
                    placeholder={t('usersConfiguration.usergroupName')}
                    valueOtherThan={groups?.map((x) => x.name).filter((x) => x !== groupToEdit?.name)}
                    onCancelClicked={() => setGroupToEdit(undefined)}
                    onAddClicked={(value) => onEditGroupName(value, groupToEdit)}
                    yesButtonText={t('general.save')}
                />
            )}
            {addGroupVisible && (
                <SingleInputModal
                    isVisible={true}
                    doNameValidation={true}
                    title={t('usersConfiguration.addUsergroup')}
                    placeholder={t('usersConfiguration.usergroupName')}
                    valueOtherThan={groups?.map((x) => x.name)}
                    onCancelClicked={() => setAddGroupVisible(false)}
                    onAddClicked={onAddGroup}
                />
            )}
            {userToRemove && (
                <YesNoModal
                    onYesClicked={onRemoveUser}
                    onNoClicked={() => setUserToRemove(undefined)}
                    description={`${t('usersConfiguration.removeUser')} ${userToRemove.name}?`}
                    yesDanger={true}
                    isVisible={true}
                />
            )}
            {groupToRemove && (
                <YesNoModal
                    onYesClicked={onRemoveGroup}
                    onNoClicked={() => setGroupToRemove(undefined)}
                    description={`${t('usersConfiguration.removeUsergroup')} ${groupToRemove.name}?`}
                    yesDanger={true}
                    isVisible={true}
                />
            )}
            {addUserVisible && <AddUserModal onFinished={onAddFinished} onCancel={() => setAddUserVisible(false)} />}
            <Footer />
        </div>
    );
};

export default UsersConfigurationPage;
