import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { VirtualDevice } from '../../models/VirtualDevice';

import EnergyManagementModal from '../energy-management-modal/EnergyManagementModal';

interface Props {
    virtualDevice: VirtualDevice;
    onClose: () => void;
}

const EnergyMonitorStatisticsModal = (props: Props): JSX.Element => {
    const { virtualDevice, onClose } = props;
    const { t } = useTranslation();

    return (
        <Modal
            width="fit-content"
            title={virtualDevice?.name ?? ''}
            cancelText={t('general.close')}
            style={{ top: 30, minWidth: '80vw' }}
            open={true}
            onCancel={onClose}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <EnergyManagementModal energyVirtualDevice={virtualDevice} />
        </Modal>
    );
};

export default EnergyMonitorStatisticsModal;
