import classNames from 'classnames';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { getSelectedFloor, getSetupInfo, setSetupInfo } from '../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { isCloud } from '../env.json';
import { getControl } from '../helpers/ControlsHelper';
import {
    getSavedSelectedFloor,
    getUserCredential,
    removeUserCredential,
    saveSelectedPageAfterLogin,
} from '../helpers/CookieHelper';
import {
    getBooleanDatapointValue,
    getColorStringWithChangedValueToSend,
    getLightColor,
    getLightValue,
    getNumberDatapointValue,
    getValueValidatedByRange,
} from '../helpers/DatapointHelper';
import useWindowDimensions from '../helpers/Hooks/useWindowDimensions';
import {
    defaultServer,
    demoMode,
    fetchImage,
    fetchSetupInfo,
    isDemo,
    pushButtonDatapoint,
    updateDatapointValue,
} from '../helpers/HttpMethods';
import { isEmpty } from '../helpers/StringHelper';
import cameraImg from '../images/camera.svg';
import CustomButton from '../main-page/main-controls/controls/components/custom-button/CustomButton';
import CustomRange from '../main-page/main-controls/controls/components/custom-slider/CustomRange';
import { Datapoint } from '../models/Datapoint';
import { Floor } from '../models/Floor';
import { FloorObject } from '../models/FloorObject';
import { VirtualDevice } from '../models/VirtualDevice';
import { DatapointSettings } from '../models/constants/DatapointSettings';
import { VirtualDeviceCategorySettings } from '../models/constants/VirtualDeviceCategorySettings';
import { DatapointNames } from '../models/enums/DatapointNames';
import { DatapointType } from '../models/enums/DatapointType';
import { Routings } from '../models/enums/Routings';
import { VirtualDeviceCategory } from '../models/enums/VirtualDeviceCategory';
import { VirtualDeviceType } from '../models/enums/VirtualDeviceType';
import styles from './FloorPlan.module.scss';
import customFloorplan from './custom-floorplan.png';
import floorplanBackground1 from './floorplan1.jpeg';
import floorplanBackground2 from './floorplan2.png';

interface PreloadedImg {
    img: string;
    imageName: string;
}

const FloorPlan = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const setupInfo = useAppSelector(getSetupInfo);
    const datapointSettings = DatapointSettings(t);
    const { windowWidth } = useWindowDimensions();
    const floorPlansDatapoints = setupInfo?.objects?.items?.filter((x) => x.type == VirtualDeviceType.FloorPlan);
    const [isLoading, setIsLoading] = useState(true);
    const [floorplanImage, setFloorplanImage] = useState<string>();
    const [preloadedImages, setPreloadedImages] = useState<PreloadedImg[]>([]);
    const [selectedFloorPlan, setSelectedFloorPlan] = useState<Floor>();
    const [mouseEnterVirtualDevice, setMouseEnterVirtualDevice] = useState<FloorObject>();
    const hostname = window.location.hostname.includes('localhost') ? defaultServer : window.location.hostname;
    const imgUrl = `http://${hostname}/webimages/`;

    const selectedFloor =
        useAppSelector(getSelectedFloor) ??
        getSavedSelectedFloor() ??
        (floorPlansDatapoints && floorPlansDatapoints?.length > 0
            ? {
                  datapointId: floorPlansDatapoints?.[0]?.datapoints?.find((x) => x.type == DatapointType.FloorConfig)
                      ?.id,
                  floorId: floorPlansDatapoints[0]?.datapoints?.find((x) => x.type == DatapointType.FloorConfig)
                      ?.Floors?.[0]?.Name,
              }
            : undefined);

    const currentDatapointFloors = useMemo(
        () =>
            floorPlansDatapoints
                ?.find((x) => x.datapoints.find((y) => y.id == selectedFloor?.datapointId))
                ?.datapoints.find((x) => x.id == selectedFloor?.datapointId)?.Floors ??
            floorPlansDatapoints?.[0]?.datapoints.find((x) => x.type == DatapointType.FloorConfig)?.Floors,
        [selectedFloor],
    );

    const currentFloor =
        currentDatapointFloors?.find((x) => x.Name == selectedFloor?.floorId) ?? currentDatapointFloors?.[0];

    useEffect(() => {
        (async () => {
            if (setupInfo) {
                setIsLoading(false);
                return;
            }

            const userCredential = getUserCredential();
            if (!userCredential) {
                history.replace(Routings.LoginPage);
                saveSelectedPageAfterLogin(Routings.FloorPlanPage);
                return;
            }

            const result = await fetchSetupInfo();

            if (result.status !== StatusCodes.OK) {
                removeUserCredential();
                history.replace(Routings.LoginPage);
                saveSelectedPageAfterLogin(Routings.FloorPlanPage);
                return;
            }
            setIsLoading(false);
            dispatch(setSetupInfo(result));
        })();
    }, []);

    useEffect(() => {
        if (setupInfo && floorPlansDatapoints && floorPlansDatapoints?.length > 0 && currentFloor) {
            const widthWithMargin = windowWidth - 50;
            const floorWidth =
                currentFloor.FloorImage.Width > widthWithMargin ? widthWithMargin : currentFloor.FloorImage.Width;
            const proportion = floorWidth / currentFloor.FloorImage.Width;

            setSelectedFloorPlan({
                ...currentFloor,
                FloorImage: {
                    ...currentFloor.FloorImage,
                    Width: floorWidth,
                    Proportions: proportion,
                    Height: currentFloor.FloorImage.Height * proportion,
                },
            });
        }
    }, [setupInfo, windowWidth, currentFloor]);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    const onLightColorSliderValueChanged = (value: number, datapoint?: Datapoint) => {
        if (datapoint) {
            onDatapointValueChanged(getColorStringWithChangedValueToSend(value, datapoint.value.toString()), datapoint);
        }
    };

    const isNumberSlider = (datapoint?: Datapoint): boolean => {
        if (!datapoint) {
            return false;
        }

        switch (datapoint.type) {
            case DatapointType.Brightness:
                return datapoint.name.includes('light');
            case DatapointType.Number:
                return !datapoint.writeprotect;
            default:
                return false;
        }
    };

    const isColorSlider = (datapoint?: Datapoint): boolean => {
        if (!datapoint) {
            return false;
        }

        switch (datapoint.type) {
            case DatapointType.Color_strip:
            case DatapointType.Color:
                return datapoint.name.includes('light');
            default:
                return false;
        }
    };

    const getVirtualDevice = (objId: number): VirtualDevice | undefined => {
        return setupInfo?.objects?.items?.find((x) => x.id == objId);
    };

    const getDatapoint = (objId: number, dpName: string): Datapoint | undefined => {
        if (!objId || !dpName) {
            return undefined;
        }

        return getVirtualDevice(objId)?.datapoints?.find((dp) => dp.name == dpName);
    };

    const getControlView = (virtualDevice?: VirtualDevice, cameraIndex?: number): JSX.Element | undefined => {
        if (!virtualDevice || !setupInfo) {
            return undefined;
        }
        const props = {
            virtualDevice: {
                ...virtualDevice,
                datapoints: virtualDevice.datapoints.map((x) =>
                    x.type == DatapointType.CameraConfig && cameraIndex != undefined
                        ? { ...x, Cameras: [x.Cameras[cameraIndex]] }
                        : x,
                ),
            },
            room: setupInfo.rooms.find((y) => virtualDevice.roomid == y.id),
            order: virtualDevice.id + 100,
            forceShowAll: true,
        };
        return getControl(virtualDevice, props);
    };

    const getControlLeft = (floorObject: FloorObject): number | undefined => {
        if (!selectedFloorPlan) {
            return undefined;
        }

        if (windowWidth < 500) {
            return -(floorObject.X * selectedFloorPlan.FloorImage.Proportions);
        }

        if (
            floorObject.X < 250 &&
            (selectedFloorPlan.FloorImage.Proportions < 1 ||
                selectedFloorPlan.FloorImage.Width * selectedFloorPlan.FloorImage.Proportions >= windowWidth - 250)
        ) {
            return 0;
        }

        if (
            getVirtualDevice(floorObject.ObjID)?.type == VirtualDeviceType.Camera &&
            floorObject.X < windowWidth - 250
        ) {
            return 0;
        }

        return undefined;
    };

    const getControlPosition = (floorObject: FloorObject): 'unset' | 'absolute' => {
        if (!selectedFloorPlan) {
            return 'unset';
        }

        if (
            floorObject.X < 250 &&
            (selectedFloorPlan.FloorImage.Proportions < 1 ||
                selectedFloorPlan.FloorImage.Width * selectedFloorPlan.FloorImage.Proportions >= windowWidth - 250)
        ) {
            return 'absolute';
        }

        if (
            floorObject.X > selectedFloorPlan.FloorImage.Width - 250 &&
            (selectedFloorPlan.FloorImage.Proportions < 1 ||
                selectedFloorPlan.FloorImage.Width * selectedFloorPlan.FloorImage.Proportions >= windowWidth - 250)
        ) {
            return 'absolute';
        }

        if (floorObject.Y < 250) {
            return 'absolute';
        }

        if (
            (getVirtualDevice(floorObject.ObjID)
                ?.datapoints?.find((x) => x.type == DatapointType.MonitorConfig)
                ?.Monitor?.filter((x) => x.View)?.length ?? 0) > 25
        ) {
            return 'absolute';
        }

        if (getVirtualDevice(floorObject.ObjID)?.type == VirtualDeviceType.Camera) {
            return 'absolute';
        }

        return 'unset';
    };

    const getBlindsPosition = (objId: number): number => {
        const blindsPositionDp = getVirtualDevice(objId)?.datapoints?.find(
            (x) => x.name === DatapointNames.VirtualBlindsPosition,
        );

        return (
            getNumberDatapointValue(blindsPositionDp, blindsPositionDp?.range) /
            (Number(blindsPositionDp?.range?.[1] ?? 100) - Number(blindsPositionDp?.range?.[0] ?? 0))
        );
    };

    useEffect(() => {
        (async () => {
            if (!selectedFloorPlan) {
                return;
            }

            setFloorplanImage(undefined);

            if (isDemo) {
                setFloorplanImage(
                    demoMode === 'normal'
                        ? selectedFloorPlan.FloorImage.Name.includes('floorplan1')
                            ? floorplanBackground1
                            : floorplanBackground2
                        : customFloorplan,
                );
                return;
            }

            if (isCloud) {
                if (preloadedImages?.length === 0 && floorPlansDatapoints) {
                    const floorPlans = floorPlansDatapoints.map((x) =>
                        x.datapoints.find((dp) => dp.type === DatapointType.FloorConfig),
                    );

                    const floorplansList: Floor[] = [];
                    floorPlans.forEach((dp) => {
                        if (dp?.Floors) {
                            floorplansList.push(...dp?.Floors);
                        }
                    });
                    const images = floorplansList
                        .map((x) => x.FloorImage)
                        .filter((val, id, array) => {
                            return array.findIndex((z) => val.Name === z.Name) == id;
                        });
                    console.log(images);
                    const result = await Promise.all<PreloadedImg>(
                        images?.map(async (x) => ({
                            img: (await fetchImage(btoa(x.Name)))?.data ?? '',
                            imageName: x.Name,
                        })) ?? [],
                    );

                    setPreloadedImages(result);
                    setFloorplanImage(
                        `data:image/png;base64, ${
                            result?.find?.((x) => x.imageName === selectedFloorPlan?.FloorImage.Name)?.img
                        }`,
                    );
                    return;
                }
                setFloorplanImage(
                    `data:image/png;base64, ${
                        preloadedImages?.find?.((x) => x.imageName === selectedFloorPlan?.FloorImage.Name)?.img
                    }`,
                );
                return;
            }

            setFloorplanImage(`${imgUrl}${selectedFloorPlan.FloorImage.Name}`);
        })();
    }, [selectedFloorPlan?.FloorImage?.Name]);

    return (
        <div
            className={styles.mainContainer}
            onMouseMove={(e) => {
                if ((e.target as HTMLElement)?.id === 'background-img') {
                    setMouseEnterVirtualDevice(undefined);
                }
            }}
        >
            <Header />
            <div className={styles.floorPlanContainer}>
                {!isLoading && selectedFloorPlan && (
                    <div
                        style={{
                            width: selectedFloorPlan.FloorImage.Width,
                            height: selectedFloorPlan.FloorImage.Height,
                        }}
                        className={styles.planContainer}
                    >
                        <img
                            id="background-img"
                            width={selectedFloorPlan.FloorImage.Width}
                            height={selectedFloorPlan.FloorImage.Height}
                            src={floorplanImage}
                        />
                        {selectedFloorPlan &&
                            selectedFloorPlan.Objects.map((floorObject, index) => (
                                <div
                                    onMouseEnter={() => {
                                        if (
                                            floorObject.ObjID &&
                                            (!floorObject.DpName ||
                                                getVirtualDevice(floorObject.ObjID)?.type == VirtualDeviceType.Camera)
                                        ) {
                                            setMouseEnterVirtualDevice(floorObject);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        const modal = document.getElementsByClassName('ant-modal-content')?.[0];
                                        if (modal) {
                                            return;
                                        }

                                        if (
                                            floorObject.ObjID &&
                                            (!floorObject.DpName ||
                                                getVirtualDevice(floorObject.ObjID)?.type == VirtualDeviceType.Camera)
                                        ) {
                                            setMouseEnterVirtualDevice(undefined);
                                        }
                                    }}
                                    key={`${selectedFloorPlan.Name}${floorObject.X}${floorObject.Y}${index}`}
                                    style={{
                                        left: floorObject.X * selectedFloorPlan.FloorImage.Proportions,
                                        top: floorObject.Y * selectedFloorPlan.FloorImage.Proportions,
                                        width: floorObject.Width * selectedFloorPlan.FloorImage.Proportions,
                                        height: floorObject.Height * selectedFloorPlan.FloorImage.Proportions,
                                        fontSize: selectedFloorPlan.FloorImage.Proportions == 1 ? '11px' : '0.8vw',
                                    }}
                                    className={styles.controlContainer}
                                >
                                    <div
                                        className={classNames(styles.control, {
                                            [styles.controlBackground]:
                                                floorObject.ObjID &&
                                                (!floorObject.DpName ||
                                                    getVirtualDevice(floorObject.ObjID)?.type ==
                                                        VirtualDeviceType.Camera),
                                            [styles.clearBoxShadow]:
                                                floorObject.ObjID &&
                                                !floorObject.DpName &&
                                                mouseEnterVirtualDevice?.ObjID == floorObject.ObjID,
                                        })}
                                    >
                                        {floorObject.IOID &&
                                            !floorObject.ObjID &&
                                            (setupInfo?.ios?.find((y) => y.id == floorObject.IOID)?.value === ''
                                                ? t('monitor.invalid')
                                                : `${setupInfo?.ios?.find((y) => y.id == floorObject.IOID)?.value} ${
                                                      floorObject.Unit ?? ''
                                                  }` ?? t('general.noData'))}
                                        {floorObject.ObjID &&
                                            floorObject.DpName &&
                                            isNumberSlider(getDatapoint(floorObject.ObjID, floorObject.DpName)) && (
                                                <CustomRange
                                                    step={1}
                                                    min={Number(
                                                        getDatapoint(floorObject.ObjID, floorObject.DpName)
                                                            ?.range?.[0] ?? 0,
                                                    )}
                                                    max={Number(
                                                        getDatapoint(floorObject.ObjID, floorObject.DpName)
                                                            ?.range?.[1] ?? 100,
                                                    )}
                                                    value={getValueValidatedByRange(
                                                        getDatapoint(floorObject.ObjID, floorObject.DpName),
                                                    )}
                                                    minTrackColor={
                                                        VirtualDeviceCategorySettings(t).find(
                                                            (setting) =>
                                                                setting.category ==
                                                                getVirtualDevice(floorObject.ObjID)?.category,
                                                        )?.color ?? '#e0e0e0'
                                                    }
                                                    onValueChanged={(value) =>
                                                        onDatapointValueChanged(
                                                            value,
                                                            getDatapoint(floorObject.ObjID, floorObject.DpName),
                                                        )
                                                    }
                                                />
                                            )}
                                        {floorObject.ObjID &&
                                            floorObject.DpName &&
                                            isColorSlider(getDatapoint(floorObject.ObjID, floorObject.DpName)) && (
                                                <CustomRange
                                                    step={1}
                                                    min={Number(
                                                        getDatapoint(floorObject.ObjID, floorObject.DpName)
                                                            ?.range?.[0] ?? 0,
                                                    )}
                                                    max={Number(
                                                        getDatapoint(floorObject.ObjID, floorObject.DpName)
                                                            ?.range?.[1] ?? 100,
                                                    )}
                                                    value={getLightValue(
                                                        getDatapoint(
                                                            floorObject.ObjID,
                                                            floorObject.DpName,
                                                        )?.value?.toString() ?? '',
                                                        getDatapoint(floorObject.ObjID, floorObject.DpName)?.range ??
                                                            [],
                                                    )}
                                                    minTrackColor={getLightColor(
                                                        getDatapoint(
                                                            floorObject.ObjID,
                                                            floorObject.DpName,
                                                        )?.value?.toString() ?? '',
                                                    )}
                                                    onValueChanged={(value) =>
                                                        onLightColorSliderValueChanged(
                                                            value,
                                                            getDatapoint(floorObject.ObjID, floorObject.DpName),
                                                        )
                                                    }
                                                />
                                            )}
                                        {floorObject.ObjID &&
                                            floorObject.DpName &&
                                            getDatapoint(floorObject.ObjID, floorObject.DpName)?.type ==
                                                DatapointType.Switch && (
                                                <Switch
                                                    height={
                                                        20 * selectedFloorPlan.FloorImage.Proportions < 12
                                                            ? 12
                                                            : 20 * selectedFloorPlan.FloorImage.Proportions
                                                    }
                                                    width={
                                                        35 * selectedFloorPlan.FloorImage.Proportions < 20
                                                            ? 20
                                                            : 35 * selectedFloorPlan.FloorImage.Proportions
                                                    }
                                                    onChange={(checked) =>
                                                        onDatapointValueChanged(
                                                            checked,
                                                            getDatapoint(floorObject.ObjID, floorObject.DpName),
                                                        )
                                                    }
                                                    checked={getBooleanDatapointValue(
                                                        getDatapoint(floorObject.ObjID, floorObject.DpName),
                                                    )}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    onColor={
                                                        VirtualDeviceCategorySettings(t).find(
                                                            (setting) =>
                                                                setting.category ==
                                                                getVirtualDevice(floorObject.ObjID)?.category,
                                                        )?.color ?? '#e0e0e0'
                                                    }
                                                    activeBoxShadow={`0 0 0px 0px #e0e0e0`}
                                                />
                                            )}
                                        {floorObject.ObjID &&
                                            floorObject.DpName &&
                                            getDatapoint(floorObject.ObjID, floorObject.DpName)?.type ==
                                                DatapointType.Button && (
                                                <CustomButton
                                                    style={{
                                                        width:
                                                            floorObject.Width *
                                                            selectedFloorPlan.FloorImage.Proportions,
                                                        height:
                                                            floorObject.Height *
                                                            selectedFloorPlan.FloorImage.Proportions,
                                                        padding: 0,
                                                    }}
                                                    className={styles.button}
                                                    onClick={() => {
                                                        const dp = getDatapoint(floorObject.ObjID, floorObject.DpName);
                                                        if (dp) pushButtonDatapoint(dp);
                                                    }}
                                                    img={
                                                        datapointSettings.find((x) => x.name == floorObject.DpName)?.img
                                                    }
                                                    imgStyle={{
                                                        width:
                                                            (floorObject.Width - 10) *
                                                            selectedFloorPlan.FloorImage.Proportions,
                                                        height:
                                                            (floorObject.Height - 10) *
                                                            selectedFloorPlan.FloorImage.Proportions,
                                                    }}
                                                />
                                            )}
                                        {floorObject.ObjID &&
                                            (!floorObject.DpName ||
                                                getVirtualDevice(floorObject.ObjID)?.type ==
                                                    VirtualDeviceType.Camera) &&
                                            (getVirtualDevice(floorObject.ObjID)?.type == VirtualDeviceType.Camera
                                                ? mouseEnterVirtualDevice?.DpName != floorObject.DpName
                                                : mouseEnterVirtualDevice?.ObjID != floorObject.ObjID) && (
                                                <div
                                                    style={{
                                                        border: `2px solid ${
                                                            VirtualDeviceCategorySettings(t).find(
                                                                (setting) =>
                                                                    setting.category ==
                                                                    getVirtualDevice(floorObject.ObjID)?.category,
                                                            )?.color
                                                        }`,
                                                        backgroundColor: getBooleanDatapointValue(
                                                            getVirtualDevice(floorObject.ObjID)?.datapoints?.find(
                                                                (x) =>
                                                                    x.name === DatapointNames.VirtualSwitch ||
                                                                    x.name === DatapointNames.VirtualEnable ||
                                                                    x.name === DatapointNames.VirtualConnection,
                                                            ),
                                                        )
                                                            ? getVirtualDevice(floorObject.ObjID)?.category ===
                                                              VirtualDeviceCategory.PowerSocket
                                                                ? '#ccb7d1'
                                                                : VirtualDeviceCategorySettings(t).find(
                                                                      (setting) =>
                                                                          setting.category ==
                                                                          getVirtualDevice(floorObject.ObjID)?.category,
                                                                  )?.color
                                                            : 'white',
                                                    }}
                                                    className={styles.controlImg}
                                                >
                                                    {(getVirtualDevice(floorObject.ObjID)?.type ===
                                                        VirtualDeviceType.Blinds ||
                                                        getVirtualDevice(floorObject.ObjID)?.type ===
                                                            VirtualDeviceType.CentralBlindsController) && (
                                                        <div
                                                            style={{
                                                                top: 0,
                                                                position: 'absolute',
                                                                opacity: 0.6,
                                                                backgroundColor: VirtualDeviceCategorySettings(t).find(
                                                                    (setting) =>
                                                                        setting.category ==
                                                                        getVirtualDevice(floorObject.ObjID)?.category,
                                                                )?.color,
                                                                width: '100%',
                                                                height:
                                                                    floorObject.Height *
                                                                    selectedFloorPlan.FloorImage.Proportions *
                                                                    getBlindsPosition(floorObject.ObjID),
                                                            }}
                                                        />
                                                    )}
                                                    <img
                                                        style={{
                                                            height: selectedFloorPlan.FloorImage.Proportions * 26,
                                                            width: selectedFloorPlan.FloorImage.Proportions * 26,
                                                        }}
                                                        src={
                                                            getVirtualDevice(floorObject.ObjID)?.type ==
                                                            VirtualDeviceType.Camera
                                                                ? cameraImg
                                                                : VirtualDeviceCategorySettings(t).find(
                                                                      (setting) =>
                                                                          setting.category ==
                                                                          getVirtualDevice(floorObject.ObjID)?.category,
                                                                  )?.img
                                                        }
                                                    />
                                                </div>
                                            )}
                                        {floorObject.ObjID &&
                                            (!floorObject.DpName ||
                                                getVirtualDevice(floorObject.ObjID)?.type ==
                                                    VirtualDeviceType.Camera) &&
                                            mouseEnterVirtualDevice?.ObjID == floorObject.ObjID &&
                                            (getVirtualDevice(floorObject.ObjID)?.type == VirtualDeviceType.Camera
                                                ? mouseEnterVirtualDevice?.DpName == floorObject.DpName
                                                : true) && (
                                                <div
                                                    className={styles.controlView}
                                                    style={{
                                                        zIndex: 998,
                                                        position: getControlPosition(floorObject),
                                                        left: getControlLeft(floorObject),
                                                        right:
                                                            floorObject.X * selectedFloorPlan.FloorImage.Proportions >
                                                                selectedFloorPlan.FloorImage.Width - 250 &&
                                                            (selectedFloorPlan.FloorImage.Proportions < 1 ||
                                                                selectedFloorPlan.FloorImage.Width *
                                                                    selectedFloorPlan.FloorImage.Proportions >=
                                                                    windowWidth - 250)
                                                                ? 0
                                                                : undefined,
                                                        top:
                                                            windowWidth < 500
                                                                ? 0
                                                                : getVirtualDevice(floorObject.ObjID)?.type ==
                                                                      VirtualDeviceType.Monitor &&
                                                                  (getVirtualDevice(floorObject.ObjID)
                                                                      ?.datapoints?.find(
                                                                          (x) => x.type == DatapointType.MonitorConfig,
                                                                      )
                                                                      ?.Monitor?.filter((x) => x.View)?.length ?? 0) >
                                                                      25
                                                                ? -floorObject.Y *
                                                                  selectedFloorPlan.FloorImage.Proportions
                                                                : floorObject.Y < 250
                                                                ? 0
                                                                : undefined,
                                                        bottom:
                                                            floorObject.Y * selectedFloorPlan.FloorImage.Proportions >
                                                            selectedFloorPlan.FloorImage.Height - 250
                                                                ? 0
                                                                : undefined,
                                                    }}
                                                >
                                                    <div style={{ minWidth: 300 }}>
                                                        {getControlView(
                                                            getVirtualDevice(floorObject.ObjID),
                                                            getVirtualDevice(floorObject.ObjID)?.type ===
                                                                VirtualDeviceType.Camera
                                                                ? Number(floorObject.DpName) - 1
                                                                : undefined,
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                    <div
                                        style={{
                                            visibility: floorObject.WebDesc ? 'visible' : 'hidden',
                                            marginTop:
                                                floorObject.Height * selectedFloorPlan.FloorImage.Proportions +
                                                ((floorObject.IOID && !floorObject.ObjID) ||
                                                (floorObject.ObjID && !floorObject.DpName)
                                                    ? 3
                                                    : 12),
                                        }}
                                        className={styles.controlName}
                                    >
                                        {floorObject.IOID &&
                                            !floorObject.ObjID &&
                                            setupInfo?.ios?.find((y) => y.id == floorObject.IOID)?.name}
                                        {floorObject.ObjID &&
                                            floorObject.DpName &&
                                            getVirtualDevice(floorObject.ObjID)?.type !== VirtualDeviceType.Camera &&
                                            (floorObject.DpName.startsWith('light.value')
                                                ? getDatapoint(floorObject.ObjID, floorObject.DpName)?.customname
                                                : !isEmpty(
                                                      datapointSettings.find((x) => x.name == floorObject.DpName)
                                                          ?.translation,
                                                  )
                                                ? datapointSettings.find((x) => x.name == floorObject.DpName)
                                                      ?.translation
                                                : floorObject.DpName)}
                                        {floorObject.ObjID &&
                                            !floorObject.DpName &&
                                            getVirtualDevice(floorObject.ObjID)?.name}
                                        {getVirtualDevice(floorObject.ObjID)?.type === VirtualDeviceType.Camera &&
                                            getVirtualDevice(floorObject.ObjID)?.datapoints?.find(
                                                (x) => x.type == DatapointType.CameraConfig,
                                            )?.Cameras?.[Number(floorObject?.DpName) - 1].Name}
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
                {(isLoading || !selectedFloorPlan) && (
                    <div className={styles.loader}>
                        <Loader type="Circles" color="#c7c7c7" height={100} width={100} />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default FloorPlan;
