import { InputNumber, Modal, Space, TimePicker } from 'antd';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getSetupInfo } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { updateDatapoint } from '../../helpers/HttpMethods';
import { Datapoint } from '../../models/Datapoint';
import { Presence } from '../../models/Presence';
import { Room } from '../../models/Room';
import { VirtualDevice } from '../../models/VirtualDevice';
import YesNoModal from '../yes-no-modal/YesNoModal';
import styles from './PresenceSimulationProgramEditModal.module.scss';
import { PresenceSimulationProgramEditModalProps } from './PresenceSimulationProgramEditModalProps';

const PresenceSimulationProgramEditModal = (props: PresenceSimulationProgramEditModalProps): JSX.Element => {
    const { timeProgramDatapoint, virtualDevice, closeModalRequested } = props;
    const { t } = useTranslation();

    const setupInfo = useAppSelector(getSetupInfo);
    const [presences, setPresences] = useState<Presence[]>(timeProgramDatapoint?.Presences ?? []);
    const [yesNoModalVisible, setYesNoModalVisible] = useState<{ onYesClicked: () => void; onNoClicked: () => void }>();
    const [isLoading, setIsLoading] = useState(false);

    const originalPresence = timeProgramDatapoint?.Presences ?? [];
    const isEdited = presences != originalPresence;

    const saveTimeProgram = async (): Promise<boolean> => {
        try {
            setIsLoading(true);
            const newTimeProgramDatapoint: Datapoint = {
                ...timeProgramDatapoint,
                Presences: presences,
            };

            const result = await updateDatapoint(newTimeProgramDatapoint);

            if (result?.status != StatusCodes.OK) {
                showError();
                setIsLoading(false);
                return false;
            }

            return true;
        } catch {
            showError();
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const onCloseRequested = () => {
        if (isEdited) {
            setYesNoModalVisible({
                onYesClicked: closeModalRequested,
                onNoClicked: () => setYesNoModalVisible(undefined),
            });
            return;
        }

        closeModalRequested();
    };

    const getObject = (objId: number): VirtualDevice | undefined => {
        return setupInfo?.objects.items.find((x) => x.id === objId);
    };

    const getRoom = (objId: number): Room | undefined => {
        return setupInfo?.rooms.find((y) => getObject(objId)?.roomid === y.id);
    };

    const onSave = async () => {
        if (await saveTimeProgram()) {
            closeModalRequested();
        }
    };

    const setEditedPresence = (index: number, value: Presence) => {
        setPresences(presences.map((x, spIndex): Presence => (spIndex === index ? value : x)));
    };

    return (
        <Modal
            title={virtualDevice.name}
            open={true}
            onCancel={onCloseRequested}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
                disabled: presences.some(
                    (x) => x.MinDuration > x.MaxDuration || x.MinNumberActivations > x.MaxNumberActivations,
                ),
                loading: isLoading,
            }}
            okText={t('general.save')}
            onOk={onSave}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width={1000}
        >
            <div className={styles.mainContainer}>
                {presences &&
                    presences.map((presence, index) => (
                        <div key={index}>
                            <div className={styles.presenceTitle}>{`${getObject(presence.ObjID)?.name} ${
                                getRoom(presence.ObjID)?.name ? ' - ' : ''
                            } ${getRoom(presence.ObjID)?.name ?? ''}`}</div>
                            <Space className={styles.switchPointContainer}>
                                <Space className={styles.switchpointRow}>
                                    <div>
                                        <div className={styles.inputTitle}>
                                            {t('presenceSimulationProgramEdit.startTime')}
                                        </div>
                                        <TimePicker
                                            allowClear={false}
                                            style={{ width: 100 }}
                                            format="HH:mm"
                                            showNow={false}
                                            value={moment(presence.StartTime, 'yyyy-MM-DDTHH:mm:ssZ')}
                                            onSelect={(value) => {
                                                setEditedPresence(index, {
                                                    ...presence,
                                                    StartTime: value.utc().format('2020-MM-DDTHH:mm:00') + 'Z',
                                                });
                                            }}
                                            onChange={(value) => {
                                                if (value) {
                                                    setEditedPresence(index, {
                                                        ...presence,
                                                        StartTime: value.utc().format('2020-MM-DDTHH:mm:00') + 'Z',
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className={styles.inputTitle}>
                                            {t('presenceSimulationProgramEdit.stopTime')}
                                        </div>
                                        <TimePicker
                                            allowClear={false}
                                            style={{ width: 100 }}
                                            format="HH:mm"
                                            showNow={false}
                                            value={moment(presence.StopTime, 'yyyy-MM-DDTHH:mm:ssZ')}
                                            onSelect={(value) => {
                                                setEditedPresence(index, {
                                                    ...presence,
                                                    StopTime: value.utc().format('2020-MM-DDTHH:mm:00') + 'Z',
                                                });
                                            }}
                                            onChange={(value) => {
                                                if (value) {
                                                    setEditedPresence(index, {
                                                        ...presence,
                                                        StopTime: value.utc().format('2020-MM-DDTHH:mm:00') + 'Z',
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </Space>
                                <Space className={styles.switchpointRow}>
                                    <div>
                                        <div className={styles.inputTitle}>
                                            {t('presenceSimulationProgramEdit.minDuration')}
                                        </div>
                                        <InputNumber
                                            className={styles.inputNumber}
                                            precision={0}
                                            status={presence.MinDuration > presence.MaxDuration ? 'error' : undefined}
                                            value={presence.MinDuration}
                                            min={1}
                                            onChange={(value) => {
                                                if (value) {
                                                    setEditedPresence(index, {
                                                        ...presence,
                                                        MinDuration: value,
                                                    });
                                                }
                                            }}
                                        />
                                        {presence.MinDuration > presence.MaxDuration && (
                                            <div className={styles.nameError}>
                                                {t(
                                                    'presenceSimulationProgramEdit.minNumberActivationsCannotBeGreaterThanMaxNumberActivations',
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className={styles.inputTitle}>
                                            {t('presenceSimulationProgramEdit.maxDuration')}
                                        </div>
                                        <InputNumber
                                            className={styles.inputNumber}
                                            precision={0}
                                            status={presence.MinDuration > presence.MaxDuration ? 'error' : undefined}
                                            value={presence.MaxDuration}
                                            min={1}
                                            onChange={(value) => {
                                                if (value) {
                                                    setEditedPresence(index, {
                                                        ...presence,
                                                        MaxDuration: value,
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </Space>
                                <Space className={styles.switchpointRow}>
                                    <div>
                                        <div className={styles.inputTitle}>
                                            {t('presenceSimulationProgramEdit.minNumberActivations')}
                                        </div>
                                        <InputNumber
                                            className={styles.inputNumber}
                                            precision={0}
                                            status={
                                                presence.MinNumberActivations > presence.MaxNumberActivations
                                                    ? 'error'
                                                    : undefined
                                            }
                                            value={presence.MinNumberActivations}
                                            min={1}
                                            onChange={(value) => {
                                                if (value) {
                                                    setEditedPresence(index, {
                                                        ...presence,
                                                        MinNumberActivations: value,
                                                    });
                                                }
                                            }}
                                        />
                                        {presence.MinNumberActivations > presence.MaxNumberActivations && (
                                            <div className={styles.nameError}>
                                                {t(
                                                    'presenceSimulationProgramEdit.minNumberActivationsCannotBeGreaterThanMaxNumberActivations',
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className={styles.inputTitle}>
                                            {t('presenceSimulationProgramEdit.maxNumberActivations')}
                                        </div>
                                        <InputNumber
                                            className={styles.inputNumber}
                                            precision={0}
                                            status={
                                                presence.MinNumberActivations > presence.MaxNumberActivations
                                                    ? 'error'
                                                    : undefined
                                            }
                                            value={presence.MaxNumberActivations}
                                            min={1}
                                            onChange={(value) => {
                                                if (value) {
                                                    setEditedPresence(index, {
                                                        ...presence,
                                                        MaxNumberActivations: value,
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </Space>
                                <div className={styles.switchpointRow}></div>
                            </Space>
                        </div>
                    ))}
            </div>
            {yesNoModalVisible && (
                <YesNoModal
                    onYesClicked={yesNoModalVisible.onYesClicked}
                    onNoClicked={yesNoModalVisible.onNoClicked}
                    description={t('timeProgramEdit.changesNotSaved')}
                    isVisible={true}
                />
            )}
        </Modal>
    );
};

export default PresenceSimulationProgramEditModal;
