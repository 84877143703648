import styles from './Footer.module.scss';
import { version } from '../../../package.json';
import { useTranslation } from 'react-i18next';

const Footer = (): JSX.Element => {
    const { t } = useTranslation();
    return <div className={styles.version}>{`myTEM SmartHome Web ${t('general.version')} ${version}`}</div>;
};

export default Footer;
