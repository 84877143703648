import i18n from 'i18next';
import { TimeProgram } from '../models/TimeProgram';

export const isEmpty = (text?: string): boolean => {
    return !text || text === '';
};

export const getVirtualDatapointNameTranslation = (text?: string): string | undefined => {
    if (!text) {
        return undefined;
    }
    const translation = i18n.t(text);
    return translation.startsWith('virtualDatapointsNames') ? undefined : translation;
};

export const findDuplicates = (arr: string[]): boolean =>
    arr.filter((item, index) => arr.indexOf(item) != index).length !== 0;

export const findEmpty = (arr: string[]): boolean => arr.some((x) => isEmpty(x));

export const getNewTimeProgramName = (timePrograms: TimeProgram[]): string => {
    const newTimeProgramTranslation = i18n.t('timeProgramEdit.newTimeProgram');
    if (!timePrograms.find((x) => x.Name == newTimeProgramTranslation)) {
        return newTimeProgramTranslation;
    }

    let i = 1;
    while (timePrograms.find((x) => x.Name == `${newTimeProgramTranslation} ${i}`)) {
        i++;
    }

    return `${newTimeProgramTranslation} ${i}`;
};

export const validatePassword = (pass: string): boolean => {
    if (pass.length < 8) {
        return false;
    }

    let score = 0;
    const passArray = Array.from(pass);

    if (passArray.some((x) => x.match(/[a-z]/i) && x === x.toUpperCase())) {
        score += 1;
    }

    if (passArray.some((x) => x.match(/[a-z]/i) && x === x.toLowerCase())) {
        score += 1;
    }

    if (passArray.some((x) => !isNaN(Number(x)))) {
        score += 1;
    }

    if (
        passArray.some(
            (x) =>
                x == '@' ||
                x == '#' ||
                x == '$' ||
                x == '^' ||
                x == '&' ||
                x == '*' ||
                x == '-' ||
                x == '_' ||
                x == '+' ||
                x == '=' ||
                x == '[' ||
                x == ']' ||
                x == '{' ||
                x == '}' ||
                x == '|' ||
                x == '\\' ||
                x == ':' ||
                x == "'" ||
                x == '!' ||
                x == ',' ||
                x == '?' ||
                x == '/' ||
                x == '`' ||
                x == '~' ||
                x == '"' ||
                x == '(' ||
                x == ')' ||
                x == ';' ||
                x == '.',
        )
    ) {
        score += 1;
    }

    return score >= 3;
};

export const getValidationNameError = (value: string): string | undefined => {
    const valueArray = Array.from(value);

    if (valueArray.some((x) => x === ' ')) {
        return i18n.t('errors.nameCannotContainsWhitespace');
    }

    if (!isNaN(Number(valueArray[0]))) {
        return i18n.t('errors.nameCannotStartWithNumber');
    }

    if (!value.match(/^[A-Za-z0-9_]*$/)) {
        return i18n.t('errors.nameCanContainOnlyLettersAndDigits');
    }

    return undefined;
};
