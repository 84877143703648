export enum Routings {
    LoginPage = '/',
    MainPage = '/main',
    FloorPlanPage = '/floorplan',
    DatalogPage = '/datalog',
    SettingsPage = '/settings',
    UsersConfigurationPage = '/users',
    AdvancedPropertiesPage = '/advanced',
    DemoPage = '/custom-demo',
}
