import { Button, Checkbox, DatePicker, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import 'reactflow/dist/style.css';
import Select from 'react-select';

import { InfoCircleOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactFlowProvider } from 'reactflow';
import { getIos } from '../../app/globalSettings';
import { useAppSelector } from '../../app/hooks';
import { fetchEnergyStatistics, pushButtonDatapoint } from '../../helpers/HttpMethods';
import { EnergyStatistic } from '../../models/EnergyStatistic';
import { VirtualDevice } from '../../models/VirtualDevice';
import { DatapointNames } from '../../models/enums/DatapointNames';
import { DatapointType } from '../../models/enums/DatapointType';
import YesNoModal from '../yes-no-modal/YesNoModal';
import CustomPie from './CustomPie/CustomPie';
import styles from './EnergyManagementModal.module.scss';
import Flowchart from './FlowChart/FlowChart';
import MainChart from './MainChart/MainChart';

const { RangePicker } = DatePicker;

interface Props {
    energyVirtualDevice: VirtualDevice;
}

const dateFormat = 'yyyy-MM-DD HH:mm';

enum DateSelectOption {
    lastHour = 'lastHour',
    last6Hour = 'last6Hour',
    last12Hour = 'last12Hour',
    last24Hour = 'last24Hour',
    lastWeek = 'lastWeek',
    custom = 'custom',
}

export enum FrameSelectionOption {
    auto = 0,
    hourly = 1,
    daily = 2,
    monthly = 3,
    yearly = 4,
}

export interface ChartItem {
    ioid: number;
    date: string;
    value: string;
    isEnergy: boolean;
}

interface Config {
    startDate: string;
    endDate: string;
    selectedDateOption: DateSelectOption;
    selectedFrameOption: FrameSelectionOption;
}

const EnergyManagementModal = (props: Props): JSX.Element => {
    const { energyVirtualDevice } = props;
    const { t } = useTranslation();

    const resetDatapoint = energyVirtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualReset,
    );

    const [isLoading, setIsLoading] = useState(false);
    const [resetConfirmationVisible, setResetConfirmationVisible] = useState(false);
    const [energyStatistic, setEnergyStatistic] = useState<EnergyStatistic>();

    const [config, setConfig] = useState<Config>({
        startDate: moment().add(-1, 'd').format(dateFormat),
        endDate: moment().format(dateFormat),
        selectedDateOption: DateSelectOption.last24Hour,
        selectedFrameOption: FrameSelectionOption.auto,
    });

    const { startDate, endDate, selectedDateOption, selectedFrameOption } = config;

    const [seconds, setSeconds] = useState(60);
    const [showCurrentDate, setShowCurrentDate] = useState(true);
    const ios = useAppSelector(getIos);

    const dateSelectOptions = useMemo(
        () => [
            { value: DateSelectOption.lastHour, label: t('general.lastHour') },
            { value: DateSelectOption.last6Hour, label: t('general.last6Hour') },
            { value: DateSelectOption.last12Hour, label: t('general.last12Hour') },
            { value: DateSelectOption.last24Hour, label: t('general.last24Hour') },
            { value: DateSelectOption.lastWeek, label: t('general.lastWeek') },
            { value: DateSelectOption.custom, label: t('general.custom') },
        ],
        [],
    );

    const frameSelectOptions = useMemo(
        () => [
            { value: FrameSelectionOption.auto, label: t('general.auto') },
            { value: FrameSelectionOption.hourly, label: t('general.hourly') },
            { value: FrameSelectionOption.daily, label: t('general.daily') },
            { value: FrameSelectionOption.monthly, label: t('general.monthly') },
            { value: FrameSelectionOption.yearly, label: t('general.yearly') },
        ],
        [],
    );

    const energyConfig = useMemo(
        () => energyVirtualDevice?.datapoints?.find((x) => x.type === DatapointType.CentralEnergyConfig)?.EnergyConfig,
        [energyVirtualDevice],
    );

    const iosConfig = useMemo(() => energyConfig?.IOConfigs, [energyConfig]);

    const flowChartIos = useMemo(
        () =>
            energyConfig?.IOConfigs?.filter((x) =>
                ios?.filter((x) => energyConfig.SinglePower.includes(x.id))?.some((z) => z.id === x.Id),
            ) ?? [],
        [energyConfig],
    );

    const getName = (ioId: number) => {
        const connectedIos = ios?.find((x) => x.id === ioId);
        return connectedIos?.name ?? '';
    };

    const energyIosConfig = useMemo(
        () =>
            energyConfig?.IOConfigs?.filter((x) =>
                ios?.filter((x) => energyConfig.SingleEnergy.some((z) => z.ioId === x.id))?.some((z) => z.id === x.Id),
            ),
        [energyConfig],
    );

    const powerIosConfig = useMemo(
        () =>
            energyConfig?.IOConfigs?.filter((x) =>
                ios?.filter((x) => energyConfig.SinglePower.includes(x.id))?.some((z) => z.id === x.Id),
            ),
        [energyConfig],
    );

    const getDates = (selectedDateOption: DateSelectOption) => {
        switch (selectedDateOption) {
            case DateSelectOption.lastHour: {
                return { endDate: moment().format(dateFormat), startDate: moment().add(-1, 'h').format(dateFormat) };
            }
            case DateSelectOption.last6Hour: {
                return { endDate: moment().format(dateFormat), startDate: moment().add(-6, 'h').format(dateFormat) };
            }
            case DateSelectOption.last12Hour: {
                return { endDate: moment().format(dateFormat), startDate: moment().add(-12, 'h').format(dateFormat) };
            }
            case DateSelectOption.last24Hour: {
                return { endDate: moment().format(dateFormat), startDate: moment().add(-1, 'd').format(dateFormat) };
            }
            case DateSelectOption.lastWeek: {
                return { endDate: moment().format(dateFormat), startDate: moment().add(-7, 'd').format(dateFormat) };
            }
        }
    };

    useEffect(() => {
        getDataLogs();
    }, [iosConfig, config]);

    const getDataLogs = async () => {
        if (!iosConfig || iosConfig.length === 0) {
            return;
        }

        setIsLoading(true);

        const result = await fetchEnergyStatistics(
            energyVirtualDevice.id,
            moment(startDate, dateFormat),
            moment(endDate, dateFormat),
            selectedFrameOption,
        );

        if (result.status !== StatusCodes.OK) {
            toast.error(t('errors.errorWhileSendingValue'));
            setIsLoading(false);
            return;
        }

        setEnergyStatistic(result.data);

        setIsLoading(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((p) => {
                if (p < 1) {
                    return 60;
                }
                return p - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (seconds === 0 && showCurrentDate && selectedDateOption !== DateSelectOption.custom) {
            setConfig((prev) => ({
                ...prev,
                endDate: moment().format(dateFormat),
            }));
        }
    }, [seconds]);

    const productionData = useMemo(() => {
        return energyStatistic?.producersTotalEnergy
            .map((x) => {
                const connectedIos = iosConfig?.find((io) => io.Id === x.ioid);
                return {
                    color: connectedIos?.Color ?? '',
                    name: getName(x.ioid),
                    value: Number((x.value / 1000).toFixed(4)),
                };
            })
            .filter((x) => x.value !== 0);
    }, [iosConfig, energyStatistic]);

    const consumptionData = useMemo(() => {
        return energyStatistic?.consumersTotalEnergy
            .map((x) => {
                const connectedIos = iosConfig?.find((io) => io.Id === x.ioid);
                return {
                    color: connectedIos?.Color ?? '',
                    name: getName(x.ioid),
                    value: Number((x.value / 1000).toFixed(4)),
                };
            })
            .filter((x) => x.value !== 0);
    }, [iosConfig, energyStatistic]);

    const getShowTimeForFrame = (frame: FrameSelectionOption) => {
        if (frame === FrameSelectionOption.auto) {
            return { format: 'HH:mm' };
        }
        if (frame === FrameSelectionOption.hourly) {
            return { format: 'HH:00' };
        }

        return false;
    };

    const getDateFormatForFrame = (frame: FrameSelectionOption) => {
        if (frame === FrameSelectionOption.monthly) {
            return 'MM-YYYY';
        }
        if (frame === FrameSelectionOption.yearly) {
            return 'YYYY';
        }

        return undefined;
    };

    const getTypePickerForFrame = (
        frame: FrameSelectionOption,
    ): 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined => {
        switch (frame) {
            case FrameSelectionOption.monthly:
                return 'month';
            case FrameSelectionOption.yearly:
                return 'year';
        }
    };

    const onFrameTypeChanged = (frame: FrameSelectionOption) => {
        switch (frame) {
            case FrameSelectionOption.hourly: {
                const start = moment(startDate, dateFormat);
                const newStart = `${start.year()}-${
                    start.month() < 9 ? '0' + (start.month() + 1) : start.month() + 1
                }-${start.date() < 10 ? '0' + start.date() : start.date()} ${
                    start.hour() < 10 ? '0' + start.hour() : start.hour()
                }:00`;
                setConfig((prev) => ({
                    ...prev,
                    startDate: newStart,
                    endDate: moment().format(dateFormat),
                    selectedFrameOption: frame,
                }));
                break;
            }
            case FrameSelectionOption.daily: {
                const start = moment(startDate, dateFormat);
                const newStart = `${start.year()}-${
                    start.month() < 9 ? '0' + (start.month() + 1) : start.month() + 1
                }-${start.date() < 10 ? '0' + start.date() : start.date()} 00:00`;
                setConfig((prev) => ({
                    ...prev,
                    startDate: newStart,
                    endDate: moment().format(dateFormat),
                    selectedFrameOption: frame,
                }));
                break;
            }
            case FrameSelectionOption.monthly: {
                const start = moment(startDate, dateFormat);
                const newStart = `${start.year()}-${
                    start.month() < 9 ? '0' + (start.month() + 1) : start.month() + 1
                }-01 00:00`;
                setConfig((prev) => ({
                    ...prev,
                    startDate: newStart,
                    endDate: moment().format(dateFormat),
                    selectedFrameOption: frame,
                }));
                break;
            }
            case FrameSelectionOption.yearly: {
                const start = moment(startDate, dateFormat);
                const newStart = `${start.year()}-01-01 00:00`;
                setConfig((prev) => ({
                    ...prev,
                    startDate: newStart,
                    endDate: moment().format(dateFormat),
                    selectedFrameOption: frame,
                }));
                break;
            }
            case FrameSelectionOption.auto: {
                setConfig((prev) => ({
                    ...prev,
                    selectedFrameOption: frame,
                }));
                break;
            }
        }
    };

    return (
        <div className={styles.mainContainer}>
            {resetDatapoint && (
                <Button
                    onClick={() => setResetConfirmationVisible(true)}
                    type="primary"
                    danger
                    style={{ width: 'fit-content', marginLeft: 'auto' }}
                >
                    {t('energyMonitor.reset')}
                </Button>
            )}
            {energyVirtualDevice && flowChartIos.length > 0 && (
                <ReactFlowProvider>
                    <Flowchart virtualDevice={energyVirtualDevice} />
                </ReactFlowProvider>
            )}
            {energyVirtualDevice && flowChartIos.length > 0 && <div className={styles.title}></div>}
            <div className={styles.rangeRow}>
                <Select
                    isSearchable={false}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 999999, fontSize: 14 }) }}
                    onChange={(v) => {
                        if (!v) {
                            return;
                        }
                        const newV = v.value;
                        const dates = getDates(newV);

                        if (!dates) {
                            setConfig((prev) => ({
                                ...prev,
                                selectedDateOption: newV,
                                selectedFrameOption:
                                    newV !== DateSelectOption.custom
                                        ? FrameSelectionOption.auto
                                        : prev.selectedFrameOption,
                            }));
                            return;
                        }

                        setConfig((prev) => ({
                            ...prev,
                            selectedDateOption: newV,
                            startDate: dates?.startDate,
                            endDate: dates?.endDate,
                            selectedFrameOption:
                                newV !== DateSelectOption.custom ? FrameSelectionOption.auto : prev.selectedFrameOption,
                        }));
                    }}
                    value={dateSelectOptions.find((x) => x.value === selectedDateOption)}
                    options={dateSelectOptions}
                    isDisabled={isLoading}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: '#a1a1a1',
                        },
                    })}
                />
                <RangePicker
                    style={{ minWidth: 330 }}
                    allowClear={false}
                    disabled={selectedDateOption !== DateSelectOption.custom || isLoading}
                    value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                    onChange={(v) => {
                        setConfig((prev) => ({
                            ...prev,
                            startDate: v?.[0]?.format(dateFormat) ?? moment().add(-1, 'd').format(dateFormat),
                            endDate: v?.[1]?.format(dateFormat) ?? moment().format(dateFormat),
                        }));
                    }}
                    disabledDate={(current) => current > moment()}
                    showNow={false}
                    showTime={getShowTimeForFrame(selectedFrameOption)}
                    format={getDateFormatForFrame(selectedFrameOption)}
                    picker={getTypePickerForFrame(selectedFrameOption)}
                />
                <Select
                    isSearchable={false}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 999999, fontSize: 14 }) }}
                    onChange={(value) => value && onFrameTypeChanged(value?.value)}
                    value={frameSelectOptions.find((x) => x.value === selectedFrameOption)}
                    options={frameSelectOptions}
                    isDisabled={selectedDateOption !== DateSelectOption.custom || isLoading}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: '#a1a1a1',
                        },
                    })}
                />
                <div className={styles.row}>
                    <Checkbox
                        disabled={selectedDateOption === DateSelectOption.custom || isLoading}
                        checked={showCurrentDate && selectedDateOption !== DateSelectOption.custom}
                        onChange={(e) => setShowCurrentDate(e.target.checked)}
                    >
                        {t('general.showLatestData')}
                    </Checkbox>
                    {showCurrentDate && selectedDateOption !== DateSelectOption.custom && <div>{`${seconds}s`}</div>}
                </div>
            </div>
            {isLoading && <Spin style={{ marginTop: 20, marginBottom: -30 }} />}
            {powerIosConfig && powerIosConfig.length > 0 && (
                <div className={styles.title}>
                    {t('energyManagement.powerHistoricalValues')}
                    <Tooltip title={t('energyManagement.valuesAbove')}>
                        <InfoCircleOutlined style={{ marginLeft: 5, cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            )}
            {powerIosConfig &&
                powerIosConfig.length > 0 &&
                energyStatistic &&
                energyStatistic?.powerStats.length === 0 &&
                !isLoading && <div className={styles.noDataError}>{t('energyManagement.noDataForFrame')}</div>}
            {powerIosConfig && energyStatistic?.powerStats && (
                <MainChart
                    stats={energyStatistic?.powerStats}
                    endDate={endDate}
                    frame={selectedFrameOption}
                    iosConfig={powerIosConfig}
                    isLoading={isLoading}
                    unit="kW"
                    title={t('energyManagement.powerHistoricalValues')}
                    downloadTitle={t('energyManagement.powerValues')}
                />
            )}
            {energyIosConfig && energyIosConfig.length > 0 && (
                <div className={styles.title}>
                    {t('energyManagement.energyHistoricalValues')}
                    <Tooltip title={t('energyManagement.valuesAbove')}>
                        <InfoCircleOutlined style={{ marginLeft: 5, cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            )}
            {energyIosConfig &&
                energyIosConfig.length > 0 &&
                energyStatistic?.energyStats &&
                energyStatistic?.energyStats.length === 0 &&
                !isLoading && <div className={styles.noDataError}>{t('energyManagement.noDataForFrame')}</div>}
            {energyIosConfig && energyStatistic?.energyStats && (
                <MainChart
                    stats={energyStatistic?.energyStats}
                    endDate={endDate}
                    frame={selectedFrameOption}
                    iosConfig={energyIosConfig}
                    isLoading={isLoading}
                    unit="kWh"
                    title={t('energyManagement.energyHistoricalValues')}
                    downloadTitle={t('energyManagement.energyValues')}
                />
            )}
            {productionData && productionData.length > 0 && (
                <div className={styles.title}>{t('energyManagement.production')}</div>
            )}
            {productionData && productionData.length > 0 && <CustomPie data={productionData} />}
            {consumptionData && consumptionData.length > 0 && (
                <div className={styles.title}>{t('energyManagement.consumption')}</div>
            )}
            {consumptionData && consumptionData.length > 0 && <CustomPie data={consumptionData} />}
            {resetConfirmationVisible && resetDatapoint && (
                <YesNoModal
                    onYesClicked={() => {
                        setResetConfirmationVisible(false);
                        pushButtonDatapoint(resetDatapoint);
                    }}
                    onNoClicked={() => setResetConfirmationVisible(false)}
                    description={t('energyManagement.resetWarning')}
                    isVisible={true}
                    yesDanger
                />
            )}
        </div>
    );
};

export default EnergyManagementModal;
