import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, setConfiguration } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { getRooms, getSelectedTab, getVirtualDevices, setSelectedTab, setSetupInfo } from '../../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { isCloud } from '../../env.json';
import { getControl } from '../../helpers/ControlsHelper';
import {
    getControlsSortingMode,
    getUserCredential,
    removeUserCredential,
    saveControlsSortingMode,
    saveSelectedTab,
} from '../../helpers/CookieHelper';
import useWindowDimensions from '../../helpers/Hooks/useWindowDimensions';
import { fetchSetupInfo, getCloudAccount, isDemo } from '../../helpers/HttpMethods';
import closeImg from '../../images/ex.svg';
import settingsImg from '../../images/settings_gray.svg';
import { ControlsSortingMode } from '../../models/enums/ControlsSortingMode';
import { Routings } from '../../models/enums/Routings';
import { TabType } from '../../models/enums/TabType';
import styles from './MainControls.module.scss';
import { ControllersProps } from './controls/ControllersProps';
import CustomButton from './controls/components/custom-button/CustomButton';

const MainControls = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const virtualDevices = useAppSelector(getVirtualDevices);
    const rooms = useAppSelector(getRooms);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const selectedTab = useAppSelector(getSelectedTab);
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();

    const allModes = [
        {
            id: ControlsSortingMode.Ranking,
            name: t('mainControls.rankingSort'),
        },
        {
            id: ControlsSortingMode.TypeThanByRanking,
            name: t('mainControls.typeThanRankingSort'),
        },
    ];

    const selectModes = allModes.map((x) => ({
        value: x.name,
        label: x.name,
        id: x.id,
    }));

    const [sortingMode, setSortingMode] = useState(
        selectModes.find((x) => x.id == (getControlsSortingMode() ?? ControlsSortingMode.Ranking)),
    );

    useEffect(() => {
        (async () => {
            if (virtualDevices && rooms) {
                setIsLoading(false);
                return;
            }

            const userCredential = getUserCredential();
            if (!userCredential && !isCloud && !isDemo) {
                history.replace(Routings.LoginPage);
                return;
            }

            if (!getCloudAccount() && isCloud && !isDemo) {
                history.replace(Routings.LoginPage);
                return;
            }

            const result = await fetchSetupInfo();

            if (result.status !== StatusCodes.OK) {
                removeUserCredential();
                history.replace(Routings.LoginPage);
                return;
            }
            setIsLoading(false);
            dispatch(setSetupInfo(result));
        })();
    }, []);

    setConfiguration({ gutterWidth: 10, breakpoints: [650, 768, 992, 1200, 1600] });

    const getEmptyView = (): JSX.Element => {
        return <div className={styles.noControlsToShow}>{t('mainControls.noControlsToShow')}</div>;
    };

    const getView = useCallback((): JSX.Element => {
        if (!virtualDevices || (virtualDevices?.length ?? 0) == 0) return <></>;

        const rowView: JSX.Element[] = [];
        let objects = virtualDevices ?? [];

        switch (selectedTab?.type) {
            case TabType.Favourite:
                objects = virtualDevices?.filter((x) => x.favourite) ?? [];
                break;
            case TabType.Room:
                if (!rooms?.find((room) => room.id == selectedTab.room?.id)) {
                    saveSelectedTab(TabType.All);
                    dispatch(setSelectedTab({ type: TabType.All }));
                    return getEmptyView();
                }

                objects = virtualDevices?.filter((x) => x.roomid == selectedTab.room?.id) ?? [];
                break;
            case TabType.Category:
                objects = virtualDevices?.filter((x) => x.category == selectedTab.category) ?? [];
                break;
        }

        objects
            .slice()
            .sort((x, y) =>
                sortingMode?.id === ControlsSortingMode.Ranking
                    ? y.ranking - x.ranking
                    : x.category - y.category || y.ranking - x.ranking,
            )
            .forEach((x) => {
                const props: ControllersProps = {
                    virtualDevice: x,
                    room: rooms?.find((y) => x.roomid == y.id),
                    order: objects.length - objects.indexOf(x) + 5,
                    forceShowAll: windowWidth < 650,
                };

                const view = getControl(x, props);

                if (view) {
                    rowView.push(
                        <Col xs={12} sm={6} md={6} lg={4} xl={3} className={styles.column} key={x.id}>
                            {view}
                        </Col>,
                    );
                }
            });

        if (rowView.length == 0) {
            return getEmptyView();
        }

        return (
            <Container>
                <Row>{rowView}</Row>
            </Container>
        );
    }, [selectedTab, virtualDevices, rooms, windowWidth, sortingMode]);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.settings}>
                <CustomButton
                    onClick={() => {
                        setSettingsVisible(!settingsVisible);
                    }}
                    className={styles.roundButton}
                    imgClassName={styles.buttonImg}
                    img={!settingsVisible ? settingsImg : closeImg}
                />
                {settingsVisible && (
                    <Select
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 999, fontSize: 13 }),
                            control: (base) => ({ ...base, fontSize: 13 }),
                        }}
                        onChange={(value) => {
                            if (!value) return;
                            setSortingMode(value);
                            saveControlsSortingMode(value.id as ControlsSortingMode);
                        }}
                        value={sortingMode}
                        className={styles.modeSelect}
                        options={selectModes}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: '#a1a1a1',
                            },
                        })}
                    />
                )}
            </div>
            {!isLoading && virtualDevices && rooms && <div className={styles.controls}>{getView()}</div>}
            {isLoading && (
                <div className={styles.loader}>
                    <Loader type="Circles" color="#c7c7c7" height={100} width={100} />
                </div>
            )}
        </div>
    );
};

export default MainControls;
