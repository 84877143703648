import { Button, DatePicker, Select, Spin } from 'antd';
import FileSaver from 'file-saver';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { utils, write } from 'xlsx';
import { getSetupInfo, setSetupInfo } from '../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { getUserCredential, removeUserCredential } from '../helpers/CookieHelper';
import { fetchDatalog, fetchSetupInfo, isDemo } from '../helpers/HttpMethods';
import { DatalogItem } from '../models/DatalogItem';
import { Ios } from '../models/Ios';
import { SetupInfo } from '../models/SetupInfo';
import { DatapointType } from '../models/enums/DatapointType';
import { Routings } from '../models/enums/Routings';
import { VirtualDeviceType } from '../models/enums/VirtualDeviceType';
import styles from './DataLogger.module.scss';
import chartDataDemo from './demoChartData.json';

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'yyyy-MM-DD HH:mm';
const serverFormat = 'yyyy-MM-DDTHH:mm:ss';
const secondFormat = 'yyyy-MM-DD HH:mm:ss';

const colors = [
    '#3366CC',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
];

const DataLogger = (): JSX.Element => {
    const history = useHistory();
    const setupInfo = useAppSelector(getSetupInfo);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [selectedIos, setSelectedIos] = useState<string[]>([]);
    const [supportedIos, setSupportedIos] = useState<Ios[]>([]);
    const [startDate, setStartDate] = useState<string>(moment().add(-1, 'd').format(dateFormat));
    const [endDate, setEndDate] = useState<string>(moment().format(dateFormat));
    const [formattedLogs, setFormattedLogs] = useState<{
        chart: {
            [x: number]: number;
            date: number;
        }[];
        download: {
            Timestamp: string;
        }[];
    }>();

    const [chartsProps, setChartsProps] = useState<{ id: number; hide: boolean }[] | undefined>(
        selectedIos?.map((x) => ({ id: Number(x), hide: false })),
    );

    const getSupportedIos = (setupInfo: SetupInfo) => {
        if (!setupInfo?.ios) {
            return [];
        }

        const monitors = setupInfo?.objects?.items?.filter((x) => x.type === VirtualDeviceType.Monitor);
        const datapoints = monitors
            .map((x) => x.datapoints)
            .flat()
            .filter((x) => x.type === DatapointType.MonitorConfig);
        const ioIds = datapoints
            .map((x) => x.Monitor)
            .flat()
            .filter((x) => x.Log)
            .map((x) => x.IOid);

        const ios = setupInfo?.ios.filter((x) => ioIds.some((y) => y === x.id));

        setChartsProps(ios.map((x) => ({ id: x.id, hide: false })));
        setSelectedIos(ios?.[0] ? [ios?.[0].id.toString()] : []);
        setSupportedIos(ios);
    };

    useEffect(() => {
        (async () => {
            if (setupInfo) {
                getSupportedIos(setupInfo);
                return;
            }
            const userCredential = getUserCredential();
            if (!userCredential) {
                history.replace(Routings.LoginPage);
                return;
            }

            const result = await fetchSetupInfo();

            if (result.status !== StatusCodes.OK) {
                removeUserCredential();
                history.replace(Routings.LoginPage);
                return;
            }
            dispatch(setSetupInfo(result));
            getSupportedIos(result);
        })();
    }, []);

    const getFilledData = (items: DatalogItem[]): DatalogItem[] => {
        try {
            const dates = items
                .map((x) => x.data.value.timestamp)
                .filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            const newItems: DatalogItem[] = [];

            selectedIos.forEach((element) => {
                const iosItems = items.filter((x) => x.data.ioid === Number(element));

                if (iosItems.length === 0) {
                    return;
                }

                let currentValue = iosItems[0].data.value.value;
                const slicedDates = dates.slice(
                    dates.findIndex((x) => x === iosItems[0].data.value.timestamp),
                    dates.findIndex((x) => x === iosItems[iosItems.length - 1].data.value.timestamp) + 1,
                );

                slicedDates.forEach((date) => {
                    const item = iosItems.find((x) => x.data.value.timestamp === date);

                    if (item) {
                        newItems.push(item);
                        currentValue = item.data.value.value;
                    } else {
                        newItems.push({
                            ...iosItems[0],
                            data: {
                                ...iosItems[0].data,
                                value: { ...iosItems[0].data.value, value: currentValue, timestamp: date },
                            },
                        });
                    }
                });
            });

            return newItems;
        } catch {
            return items;
        }
    };

    const getDatalogs = async () => {
        if (selectedIos.length === 0 || isDemo) {
            return [];
        }

        const result = await fetchDatalog(
            selectedIos.map((x) => Number(x)),
            moment(startDate, dateFormat),
            moment(endDate, dateFormat),
        );

        if (result.status !== StatusCodes.OK) {
            toast.error(t('errors.errorWhileSendingValue'));
            setIsLoading(false);
            return;
        }

        const removedMilisecondsData = result.data
            .filter((x) => x.data.value.timestamp)
            .map((x) => ({
                ...x,
                data: {
                    ...x.data,
                    value: {
                        ...x.data.value,
                        timestamp: moment(x.data.value.timestamp).format(serverFormat),
                    },
                },
            }));

        const filtered = getFilledData(removedMilisecondsData).filter(
            (x) =>
                moment(x.data.value.timestamp) >= moment(startDate, dateFormat) &&
                moment(x.data.value.timestamp) <= moment(endDate, dateFormat),
        );

        return filtered;
    };

    const defaultOption = useMemo(() => (supportedIos?.[0] ? [supportedIos?.[0].id.toString()] : []), [supportedIos]);
    const options = useMemo(
        () =>
            supportedIos?.map((x, index) => (
                <Option value={x.id.toString()} key={`(${index + 1}) ${x.name}`}>{`(${index + 1}) ${x.name}`}</Option>
            )),
        [supportedIos],
    );

    const selectBar = (e: any) => {
        setChartsProps((prev) =>
            prev?.map((x) => (x.id === Number(e.dataKey) ? { ...x, hover: false, hide: !x.hide } : x)),
        );
    };

    const getNumber = (value: string) => {
        if (value.toString() === 'true') {
            return 1;
        } else if (value.toString() === 'false') {
            return 0;
        }

        const num = Number(value);

        if (isNaN(num)) {
            return 0;
        }

        return num;
    };

    function groupBy<T>(arr: T[], fn: (item: T) => any) {
        return arr.reduce<Record<string, T[]>>((prev, curr) => {
            const groupKey = fn(curr);
            const group = prev[groupKey] || [];
            group.push(curr);
            return { ...prev, [groupKey]: group };
        }, {});
    }

    const getFormatted = async () => {
        if (isDemo) {
            const formatted = chartDataDemo.map((x, index) => ({
                date: moment().valueOf() - index * 60 * 60 * 1000,
                [1]: x.data,
            }));
            setFormattedLogs({ chart: formatted, download: [] });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const dataLogs = await getDatalogs();
        if (!dataLogs?.length) {
            setIsLoading(false);
            return { chart: [], download: [] };
        }

        const grouped = groupBy(
            dataLogs.map((x) => ({
                date: moment(x.data.value.timestamp).valueOf(),
                [x.data.ioid]: getNumber(x.data.value.value),
            })),
            (x) => x.date,
        );
        const formatted = Object.keys(grouped).map((x) => ({
            ...grouped[x].reduce((prev, curr) => {
                return {
                    ...prev,
                    ...curr,
                };
            }),
        }));

        const groupedDownload = groupBy(
            dataLogs
                .sort((a, b) => (moment(a.data.value.timestamp).isBefore(moment(b.data.value.timestamp)) ? -1 : 1))
                .map((x) => ({
                    Timestamp: moment(x.data.value.timestamp).format(secondFormat),
                    [supportedIos.find((z) => z.id === x.data.ioid)?.name ?? '']: getNumber(x.data.value.value),
                })),
            (x) => x.Timestamp,
        );
        const formattedDownload = Object.keys(groupedDownload).map((x) => ({
            ...groupedDownload[x].reduce((prev, curr) => {
                return {
                    ...prev,
                    ...curr,
                };
            }),
        }));

        setFormattedLogs({ chart: formatted, download: formattedDownload });
        setIsLoading(false);
    };

    useEffect(() => {
        if (supportedIos.length > 0 && selectedIos.length > 0) {
            getFormatted();
        }
    }, [selectedIos, startDate, endDate, supportedIos]);

    const onDownload = () => {
        if (!formattedLogs?.download) {
            return;
        }

        const workbook = utils.book_new();
        const filename = t('header.datalog');
        const dataSheet = utils.json_to_sheet(formattedLogs.download);
        utils.book_append_sheet(workbook, dataSheet, filename.replace('/', ''));
        const excel = write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const finalData = new Blob([excel]);
        FileSaver.saveAs(finalData, t('header.datalog') + '.xlsx');
    };

    return (
        <div className={styles.mainContainer}>
            <Header />
            <div className={styles.contentContainer}>
                {supportedIos.length > 0 && (
                    <div className={styles.configContainer}>
                        <Select
                            disabled={isLoading || isDemo}
                            mode="multiple"
                            maxTagCount={1}
                            maxTagTextLength={33}
                            allowClear={false}
                            style={{ width: '100%', maxWidth: 350, marginRight: 20, maxHeight: 35 }}
                            defaultValue={defaultOption}
                            onChange={(v) => setSelectedIos(v)}
                        >
                            {options}
                        </Select>
                        <RangePicker
                            disabled={isDemo}
                            style={{ minWidth: 350 }}
                            allowClear={false}
                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                            className={styles.rangePicker}
                            onChange={(v) => {
                                setStartDate(v?.[0]?.format(dateFormat) ?? moment().add(-1, 'd').format(dateFormat));
                                setEndDate(v?.[1]?.format(dateFormat) ?? moment().format(dateFormat));
                            }}
                            showNow={false}
                            showTime={{ format: 'HH:mm' }}
                        />
                        {formattedLogs?.download && formattedLogs?.download?.length > 0 && (
                            <Button
                                onClick={onDownload}
                                disabled={isLoading}
                                type="primary"
                                style={{ width: 'fit-content', marginLeft: 'auto' }}
                            >
                                {t('energyManagement.downloadDataSheet')}
                            </Button>
                        )}
                    </div>
                )}
                {(formattedLogs?.chart?.length ?? 0) === 0 && !isLoading && (
                    <div className={styles.error}>{t('energyManagement.noDataForFrame')}</div>
                )}
                {isLoading && <Spin className={styles.loading} size="large" />}
                {formattedLogs && (
                    <ResponsiveContainer height={400}>
                        <ComposedChart data={formattedLogs.chart}>
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                                dataKey="date"
                                type="number"
                                domain={[
                                    moment(startDate, dateFormat).valueOf(),
                                    moment(endDate, dateFormat).valueOf(),
                                ]}
                                tickFormatter={(label) => {
                                    return moment(label).format(secondFormat);
                                }}
                            />
                            <YAxis />
                            <Tooltip
                                labelFormatter={(label) => {
                                    return moment(label).format(secondFormat);
                                }}
                            />
                            <Legend onClick={selectBar} />
                            <ReferenceLine y={0} stroke="#d1d1d1" strokeDasharray="3 3" />
                            {selectedIos?.map((x, index) => (
                                <Line
                                    dot={false}
                                    key={x}
                                    dataKey={x}
                                    name={supportedIos.find((z) => z.id === Number(x))?.name}
                                    type="stepAfter"
                                    stroke={colors[index > 19 ? 19 : index]}
                                    hide={chartsProps?.find((z) => z.id === Number(x))?.hide}
                                />
                            ))}
                        </ComposedChart>
                    </ResponsiveContainer>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default DataLogger;
