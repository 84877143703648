import styles from './GenericControls.module.scss';
import { DatapointType } from '../../../../models/enums/DatapointType';
import { pushButtonDatapoint, updateDatapointValue } from '../../../../helpers/HttpMethods';
import { getBooleanDatapointValue, getNumberDatapointValue } from '../../../../helpers/DatapointHelper';
import { ControllersProps } from '../ControllersProps';
import { VirtualDeviceCategorySettings } from '../../../../models/constants/VirtualDeviceCategorySettings';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../components/custom-button/CustomButton';
import CustomRange from '../components/custom-slider/CustomRange';
import { StatusCodes } from 'http-status-codes';
import { Datapoint } from '../../../../models/Datapoint';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { getVirtualDatapointNameTranslation } from '../../../../helpers/StringHelper';
import ControlHeader from '../components/control-header/ControlHeader';

const GenericControls = (props: ControllersProps): JSX.Element => {
    const { virtualDevice, room, onEditButtonClick } = props;
    const { t } = useTranslation();
    const category = VirtualDeviceCategorySettings(t).find((x) => x.category == virtualDevice.category);
    const border = '1px solid ' + category?.color;

    const labels = virtualDevice.datapoints.filter(
        (x) => (x.type == DatapointType.Number && x.writeprotect) || x.type == DatapointType.StatusLight,
    );
    const sliders = virtualDevice.datapoints.filter(
        (x) => (x.type == DatapointType.Number && !x.writeprotect) || x.type == DatapointType.Brightness,
    );
    const switches = virtualDevice.datapoints.filter((x) => x.type == DatapointType.Switch);
    const buttons = virtualDevice.datapoints.filter((x) => x.type == DatapointType.Button);

    const onDatapointValueChanged = async (value: boolean | number | string, datapoint?: Datapoint) => {
        if (datapoint) {
            const result = await updateDatapointValue(datapoint, value);
            if (result.status !== StatusCodes.OK) {
                toast.error(t('errors.errorWhileSendingValue'));
                return;
            }
        }
    };

    return (
        <div className={styles.mainContainer} style={{ border: border }}>
            <ControlHeader onEditButtonClick={onEditButtonClick} virtualDevice={virtualDevice} room={room} />
            {labels &&
                labels.map((x) => (
                    <div key={x.id} className={classNames(styles.titleValue, styles.datapoint)}>
                        <div className={styles.dpName}>
                            {getVirtualDatapointNameTranslation('virtualDatapointsNames.' + x.name) ?? x.name}
                        </div>
                        <div>{x?.value?.toString()}</div>
                    </div>
                ))}
            <div className={styles.span} />
            {buttons &&
                buttons.map((x) => (
                    <div key={x.id} className={styles.button}>
                        <CustomButton
                            onClick={() => pushButtonDatapoint(x)}
                            title={getVirtualDatapointNameTranslation('virtualDatapointsNames.' + x.name) ?? x.name}
                        />
                    </div>
                ))}
            {sliders &&
                sliders.map((datapoint) => (
                    <div className={styles.sliderDatapoint} key={datapoint.id}>
                        <div className={styles.sliderDatapointTitle}>
                            {getVirtualDatapointNameTranslation('virtualDatapointsNames.' + datapoint.name) ??
                                datapoint.name}
                        </div>
                        <CustomRange
                            step={1}
                            min={Number(datapoint?.range[0] ?? 0)}
                            max={Number(datapoint?.range[1] ?? 100)}
                            value={getNumberDatapointValue(datapoint, datapoint?.range)}
                            minTrackColor={category?.color ?? ''}
                            onValueChanged={(value) => onDatapointValueChanged(value, datapoint)}
                        />
                    </div>
                ))}
            {switches &&
                switches.map((x) => (
                    <div key={x.id} className={classNames(styles.titleValue, styles.datapoint)}>
                        <div>{getVirtualDatapointNameTranslation('virtualDatapointsNames.' + x.name) ?? x.name}</div>
                        <Switch
                            height={20}
                            width={35}
                            className={styles.switch}
                            onChange={(checked) => onDatapointValueChanged(checked, x)}
                            checked={getBooleanDatapointValue(x)}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor={category?.color}
                            activeBoxShadow={`0 0 0px 0px ${category?.color}`}
                        />
                    </div>
                ))}
        </div>
    );
};

export default GenericControls;
