import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAdvancedPropertyRoom, getSetupInfo, setSetupInfo } from '../app/globalSettings';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { getUserCredential, removeUserCredential } from '../helpers/CookieHelper';
import { fetchAdvancedProperties, fetchSetupInfo } from '../helpers/HttpMethods';
import { Routings } from '../models/enums/Routings';
import { PropertyTemplate } from '../models/PropertyTemplate';
import { Col, Container, Row } from 'react-grid-system';

import styles from './AdvancedProperties.module.scss';
import RoomAdvanceProperty from './RoomAdvanceProperty/RoomAdvanceProperty';
import { Spin } from 'antd';

const AdvancedPropertiesPage = (): JSX.Element => {
    const setupInfo = useAppSelector(getSetupInfo);
    const selectedRoom = useAppSelector(getAdvancedPropertyRoom);
    const loggedUser = getUserCredential();
    const history = useHistory();
    const dispatch = useAppDispatch();

    const rooms = setupInfo?.rooms;
    const virtualDevices = setupInfo?.objects?.items?.filter((x) => x?.properties?.length > 0);
    const connectedVirtualDevices =
        selectedRoom === 0
            ? virtualDevices?.filter((x) => !rooms?.map((y) => y.id).some((z) => z === x.roomid))
            : virtualDevices?.filter((x) => x.roomid === selectedRoom);

    const [isLoading, setIsLoading] = useState(false);
    const [propertyTemplates, setPropertyTemplates] = useState<PropertyTemplate[]>();

    const getAdvancedProperties = async () => {
        const result = await fetchAdvancedProperties();

        if (result.status !== StatusCodes.OK) {
            removeUserCredential();
            history.replace(Routings.LoginPage);
            return;
        }

        setPropertyTemplates(result.data);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (!setupInfo) {
                await getNewSetupInfo();
            }

            await getAdvancedProperties();

            setIsLoading(false);
        })();
    }, []);

    const getNewSetupInfo = async () => {
        if (!loggedUser) {
            history.replace(Routings.LoginPage);
            return;
        }

        const result = await fetchSetupInfo();

        if (result.status !== StatusCodes.OK) {
            removeUserCredential();
            history.replace(Routings.LoginPage);
            return;
        }

        dispatch(setSetupInfo(result));
    };

    return (
        <div className={styles.mainContainer}>
            <Header />
            {connectedVirtualDevices && propertyTemplates && virtualDevices && !isLoading && (
                <Container className={styles.cards}>
                    <Row>
                        {connectedVirtualDevices
                            .sort((n1, n2) => n1.properties.length - n2.properties.length)
                            .map((virtualDevice) => (
                                <Col key={virtualDevice.id} xs={12} sm={12} md={12} lg={6} xl={4}>
                                    <RoomAdvanceProperty
                                        virtualDevice={virtualDevice}
                                        propertyTemplates={propertyTemplates}
                                    />
                                </Col>
                            ))}
                    </Row>
                </Container>
            )}
            {isLoading && <Spin size="large" className={styles.loading} />}
            <Footer />
        </div>
    );
};

export default AdvancedPropertiesPage;
