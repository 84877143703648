import { Button } from 'antd';
import FileSaver from 'file-saver';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { utils, write } from 'xlsx';
import { getIos } from '../../../app/globalSettings';
import { useAppSelector } from '../../../app/hooks';
import { GraphType, IoConfig } from '../../../models/EnergyConfig';
import { EnergyStatisticItem } from '../../../models/EnergyStatistic';
import { FrameSelectionOption } from '../EnergyManagementModal';
import styles from './MainChart.module.scss';

type Props = {
    stats: EnergyStatisticItem[];
    frame: FrameSelectionOption;
    endDate: string;
    iosConfig: IoConfig[];
    isLoading: boolean;
    unit: string;
    title: string;
    downloadTitle: string;
};

const serverFormat = 'yyyy-MM-DDTHH:mm:ss';

const MainChart = ({ stats, frame, iosConfig, isLoading, endDate, unit, title, downloadTitle }: Props): JSX.Element => {
    const ios = useAppSelector(getIos);
    const { t } = useTranslation();

    const lineIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Line), [iosConfig]);
    const areaIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Area), [iosConfig]);
    const beamIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Beam), [iosConfig]);

    const [chartsProps, setChartsProps] = useState<{ id: number; hide: boolean }[] | undefined>([
        { id: 0, hide: false },
        ...(iosConfig
            ?.filter((x) => x.Graph === GraphType.Line || x.Graph === GraphType.Area || x.Graph === GraphType.Beam)
            ?.map((x) => ({ id: x.Id, hide: false })) ?? []),
    ]);

    const getName = (ioId: number) => {
        const connectedIos = ios?.find((x) => x.id === ioId);
        return connectedIos?.name ?? '';
    };

    const selectBar = (e: any) => {
        setChartsProps((prev) => prev?.map((x) => (x.id === e.dataKey ? { ...x, hover: false, hide: !x.hide } : x)));
    };

    const getDateFormat = () => {
        switch (frame) {
            case FrameSelectionOption.hourly:
                return 'DD.MM.yyyy HH:mm';
            case FrameSelectionOption.daily:
                return 'DD.MM.yyyy';
            case FrameSelectionOption.monthly:
                return 'MM.yyyy';
            case FrameSelectionOption.yearly:
                return 'yyyy';
            default:
                return 'DD.MM.yyyy HH:mm:ss';
        }
    };

    const formatedLogs = useMemo(() => {
        if (!stats?.length) {
            return { chart: [], download: [] };
        }

        const formated = stats.map((point) => {
            const data = point.items.reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr.ioid]: Number((curr.value / 1000).toFixed(4)),
                };
            }, {});

            return {
                name: moment(point.date, serverFormat).format(getDateFormat()),
                ...data,
                0: Number((point.total / 1000).toFixed(4)),
            };
        });

        const formatedDownload = stats.map((point, index) => {
            const data = point.items.reduce((prev, curr) => {
                return {
                    ...prev,
                    [getName(curr.ioid)]: (curr.value / 1000).toFixed(4),
                };
            }, {});

            return {
                'From date': moment(point.date, serverFormat).format(getDateFormat()),
                'To date':
                    index === stats.length - 1
                        ? moment(endDate, 'yyyy-MM-DD HH:mm').format(getDateFormat())
                        : moment(stats[index + 1].date, serverFormat).format(getDateFormat()),
                ...data,
                Total: (point.total / 1000).toFixed(4),
            };
        });

        return { chart: formated, download: formatedDownload };
    }, [stats, frame]);

    const onDownload = () => {
        const workbook = utils.book_new();
        const dataSheet = utils.json_to_sheet(formatedLogs.download);
        utils.book_append_sheet(workbook, dataSheet, downloadTitle.replace('/', ''));
        const excel = write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const finalData = new Blob([excel]);
        FileSaver.saveAs(finalData, downloadTitle + '.xlsx');
    };

    return (
        <div className={styles.wrapper}>
            {formatedLogs?.download?.length > 0 && (
                <Button
                    onClick={onDownload}
                    disabled={isLoading}
                    type="primary"
                    style={{ width: 'fit-content', marginLeft: 'auto' }}
                >
                    {t('energyManagement.downloadDataSheet')}
                </Button>
            )}
            <ResponsiveContainer height={400}>
                <ComposedChart data={formatedLogs.chart}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis
                        width={100}
                        tickFormatter={(label) => {
                            return label + ` ${unit}`;
                        }}
                    />
                    <Tooltip formatter={(label) => label + ` ${unit}`} />
                    <Legend onClick={selectBar} />
                    <ReferenceLine y={0} stroke="#d1d1d1" strokeDasharray="3 3" />
                    {areaIos?.map((x) => (
                        <Area
                            key={x.Id}
                            type="step"
                            dataKey={x.Id}
                            name={getName(x.Id)}
                            fill={x.Color}
                            fillOpacity={0.3}
                            stroke={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                            dot={false}
                        />
                    ))}
                    {beamIos?.map((x) => (
                        <Bar
                            key={x.Id}
                            dataKey={x.Id}
                            name={getName(x.Id)}
                            barSize={20}
                            fill={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                        />
                    ))}
                    {lineIos?.map((x) => (
                        <Line
                            dot={false}
                            key={x.Id}
                            dataKey={x.Id}
                            name={getName(x.Id)}
                            type="step"
                            stroke={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                        />
                    ))}
                    <Line
                        key={0}
                        dataKey={0}
                        name={'Total'}
                        type="step"
                        stroke={'#000000'}
                        hide={chartsProps?.find((z) => z.id === 0)?.hide}
                        dot={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default MainChart;
